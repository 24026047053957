import {httpService} from "../http-service";

export interface RoleManage {
    roleName: string;
    menus?: any[];
    status: string;
}

class RoleManageService {

    /**
     * 获取角色列表
     */
    getRoleList(data:any) {
        return httpService.post("/role/getRoleList", data);
    }

    /**
     * 获取角色下的菜单
     */
    getRoleMenu(roleId: string) {
        return httpService.post("/role/getRoleMenu", {id: roleId});
    }

    /**
     * 添加角色
     */
    addRole(adminRole: RoleManage) {
        return httpService.post("/role/addRole", adminRole);
    }

    /**
     * 更改角色
     */
    updateRole(adminRole: RoleManage) {
        return httpService.post("/role/updateRole", adminRole);
    }

    /**
     * 删除角色
     */
    deleteRole(roleId:string) {
        return httpService.post("/role/deleteRole",{id: roleId});
    }

}

export const roleManageService = new RoleManageService();
