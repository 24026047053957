import { message } from "antd";
import React, { useState, useEffect } from "react";
import { dealerManageService } from "../../../service/admin";
import { DataList } from "./DataList";
import { IPayload, SearchForm } from "./SearchForm";

export const UserManage = () => {

    const [payload, setPayload] = useState<IPayload>({
        username: null,
        status: null,
        dealerId: null
    })

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState(false);

    const [dealerList, setDealerList] = useState<any[]>([]);

    useEffect(() => {
        getDealerList()
    }, [])

    /**
     * 查询经销商列表
     */
    const getDealerList = () => {
        dealerManageService.getDealerList({})
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setDealerList(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                message.error("发生未知错误")
            })
    }

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    return <div>
        <SearchForm updatePayload={updatePayload} payload={payload} searchLoading={searchLoading} dealerList={dealerList} />
        <DataList payload={payload} updateLoading={updateLoading} searchLoading={searchLoading} dealerList={dealerList} />
    </div>
}
