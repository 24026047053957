import {httpService} from "../http-service";

export interface Menu {
    menuName: string;
    path?: string;
    parentId?: string;
    iconName?: string;
    status?: string;
}

class MenuService {

    /**
     * 获取菜单列表
     */
    getMenuList() {
        return httpService.post("/menu/getMenuList", {});
    }

    /**
     * 获取树状菜单列表
     */
     getMenuTree() {
        return httpService.post("/menu/getMenuTree", {});
    }

    /**
     * 获取树状菜单列表
     */
     getPowerMenuTree() {
        return httpService.post("/menu/getPowerMenuTree", {});
    }

    /**
     * 添加菜单
     * @param menu
     */
    addMenu(menu: Menu) {
        return httpService.post("/menu/addMenu", menu);
    }

    /**
     * 更新菜单
     * @param menu
     */
    updateMenu(menu: Menu) {
        return httpService.post("/menu/updateMenu", menu);
    }

    /**
     * 删除菜单
     * @param menuId
     */
    deleteMenu(menuId: string) {
        return httpService.post("/menu/deleteMenu", {id: menuId})
    }
}

export const menuService = new MenuService();
