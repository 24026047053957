import { Form, message, Modal, Select, Upload, Input, Radio } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PackageGroupDataType } from '../../recharge-package-manage/packge-group';
import { simService, vendorManageService } from '../../../../service';

const { Option } = Select

interface ImportCardNumberProps {
    refresh: () => void
    packageGroupList: PackageGroupDataType[]
    // payParamList: any[]
}

interface FormParams {
    importBatch: string;
    packageGroupId: string;
    cardNumber: string;
    operator: string;
    vendor: string;
    startNumber: string | number;
    endNumber: string | number;
}

export const ImportCardNumber = forwardRef((props: ImportCardNumberProps, ref) => {

    const [form] = Form.useForm()

    const [visible, setVisible] = useState<boolean>(false)

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const [vendorList, setVendorList] = useState([])

    const [cardNumber, setCardNumber] = useState('number')

    useEffect(() => {
        getVendorList()
    }, [])

    useImperativeHandle(ref, () => ({
        updateVisible: () => setVisible(true)
    }))

    // 获取卡商列表
    const getVendorList = () => {
        vendorManageService.getVendorList()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    setVendorList(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    const getFileList = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onImportCardNumberCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const onImportCardNumberOk = () => {
        form.validateFields().then(() => {
            const { importBatch, packageGroupId, cardNumber, operator, vendor,
                startNumber, endNumber } = form.getFieldsValue()
            // 文件导入
            if (cardNumber === 'file') {
                handleFileImport(importBatch, packageGroupId)
            } else {
                let packageGroupName = props.packageGroupList.find(it => it.id === packageGroupId)?.packageGroupName || ''
                let params = {
                    importBatch,
                    packageGroupId,
                    packageGroupName,
                    cardNumber,
                    operator,
                    vendor,
                    startNumber,
                    endNumber
                }
                handleCardNumberImport(params)
            }
        })
    }

    const handleCardNumberImport = (params: FormParams) => {
        //TODO 处理号段导入逻辑
        simService.importNumber(params).then((res: any) => {
            const responseMsg = res.data;
            if (responseMsg.status === 0) {
                onImportCardNumberCancel()
                setCardNumber('number')
                message.success('导入成功')
                props.refresh()
            } else {
                message.error(responseMsg.msg)
            }
        })
            .catch((err) => {
                message.error(err.msg)
            })
    }

    const handleFileImport = (importBatch: string, packageGroupId: string) => {
        let packageGroupName = props.packageGroupList.find(it => it.id === packageGroupId)?.packageGroupName || ''
        const formData = new FormData()
        formData.append('file', fileList[0] as RcFile)
        formData.append('importBatch', importBatch ? importBatch : '')
        formData.append('packageGroupName', packageGroupName)
        formData.append('packageGroupId', packageGroupId)
        // formData.append('payParam', payParam)
        simService.importSim(formData)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    onImportCardNumberCancel()
                    setCardNumber('number')
                    Modal.warning({
                        title: responseMsg.msg
                    })
                    props.refresh()
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    const onDownload = () => {
        simService.exportSim().then((response) => {
            let blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            let url = window.URL.createObjectURL(blob);
            let aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = url;
            aLink.setAttribute("download", decodeURIComponent(response.headers['name'] || ''));
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
        })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    /**
    * 验证文件类型是否为Excel
    * @param name  文件名
    * @param size  文件大小
    */
    const checkFileType = (name: string, size: any) => {
        let suf = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
        if (suf === 'xlsx' || suf === 'xls') {
            return true;
        }
        message.error("文件类型错误，请重新选择文件！")
        return false;
    }

    const onCardNumberChange = (e: any) => {
        setCardNumber(e.target.value)
    }

    const getFormItems = () => {
        if (cardNumber === 'number') {
            return <>
                <Form.Item label={"运营商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"operator"} initialValue={null}
                    rules={[{ required: true, message: '请选择运营商' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        <Option value="CMCC">中国移动</Option>
                        <Option value="CNC">中国联通</Option>
                        <Option value="CT">中国电信</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"卡商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"vendor"} initialValue={null}
                    rules={[{ required: true, message: '请选择卡商' }]}>
                    <Select placeholder={"请选择"} allowClear maxTagCount={1} maxTagTextLength={4}>
                        {
                            vendorList.map((item: any) => {
                                return <Option value={item.vendorName}>{item.vendorName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={"开始号段"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"startNumber"} initialValue={[]}
                    rules={[{ required: true, message: '请输入开始号段' }]}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label={"结束号段"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"endNumber"} initialValue={[]}
                    rules={[{ required: true, message: '请输入结束号段' }]}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            </>
        }
        return <>
            <Form.Item label={"卡号文件"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
                <Form.Item name={"cardFile"} initialValue={null}
                    valuePropName="fileList" getValueFromEvent={getFileList} noStyle
                    rules={[{ required: true, message: '请上传文件' }]}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                        <p className="ant-upload-hint">只支持Excel文件</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <div className='ant-form-item'>
                <div className='ant-row ant-form-item-row'>
                    <div className='ant-col ant-col-5'></div>
                    <div className='ant-col ant-col-19'>
                        {/* eslint-disable-next-line */}
                        <a onClick={onDownload}>下载导入卡号模版</a>
                    </div>
                </div>
            </div>
        </>
    }

    const uploadProps = {
        name: 'file',
        maxCount: 1,
        beforeUpload: async (file: UploadFile<any>, fileList: any) => {
            if (checkFileType(file.name, file.size)) {
                setFileList([...fileList, file])
            }
            return false;
        },
    }
    return <div>
        <Modal
            title='导入卡号'
            open={visible}
            onCancel={onImportCardNumberCancel}
            onOk={onImportCardNumberOk}
        >
            <Form form={form}>
                <Form.Item label={"批次号"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"importBatch"} initialValue={null}>
                    <Input />
                </Form.Item>
                <Form.Item label={"套餐组"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageGroupId"} initialValue={null}
                    rules={[{ required: true, message: '请选择套餐组' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        {
                            props.packageGroupList.map((item) => {
                                return <Option value={item.id}>{item.packageGroupName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={"号段导入"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"cardNumber"} initialValue='number'
                    rules={[{ required: true, message: '请选择支付平台' }]}>
                    <Radio.Group onChange={onCardNumberChange}>
                        <Radio value={'number'}>号段导入</Radio>
                        <Radio value={'file'}>文件导入</Radio>
                    </Radio.Group>
                </Form.Item>
                {getFormItems()}
                {/* <Form.Item label={"支付参数"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"payParam"} initialValue={null}
                    rules={[{ required: true, message: '请选择支付参数' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        {
                            props.payParamList.map((item) => {
                                return <Option value={item.payParamName}>{item.payParamName}</Option>
                            })
                        }
                    </Select>
                </Form.Item> */}
            </Form>
        </Modal>
    </div>
})