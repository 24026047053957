import React from "react";
import { StatisticCard } from '@ant-design/pro-components';

interface ICStatisticCardProps {
    data: {
        title: string;
        value: string | number;
        suffix?: string;
    }
}

export const CStatisticCard = (props: ICStatisticCardProps) => {
    const { data } = props
    const { title, value, suffix } = data
    return <>
        <StatisticCard
            style={{ textAlign: 'left' }}
            statistic={{
                title,
                value,
                suffix
            }}
        />
    </>
}