import { Button, Form, Input, message, Modal, Radio, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { payParamService } from "../../../service/issue-manage";

// const { Option } = Select

interface PayParamDataType {
    id: string; // id
    key?: string;
    payParamName?: string; // 支付参数规则名称
    operator?: string; // 运营商
    vendor?: string; //卡商
    payPlatform?: 'WxPay' | 'AliPay'; //支付平台
    sellerId?: string;
    appId?: string;
    apiKey?: string;
    appSecret?: string;
    publicKey?: string;
    status?: string | undefined; // 状态
    updateTime?: string; // 创建日期
}

export const PayParam = () => {

    const [form] = Form.useForm()

    const [tableData, setTableData] = useState<PayParamDataType[]>([])

    // const [vendorList, setVendorList] = useState([])

    const [visible, setVisible] = useState<boolean>(false)

    const [searchLoading, setSearchLoading] = useState<boolean>(false)

    const [payWay, setPayWay] = useState<'WxPay' | 'AliPay'>('WxPay')

    const editInfo = useRef<PayParamDataType | null>(null)

    useEffect(() => {
        // 获取卡商列表
        // getVendorList()
        // 获取支付参数列表
        getPayParamList()
    }, [])

    // 获取卡商列表
    // const getVendorList = () => {
    //     vendorManageService.getVendorList()
    //         .then(res => {
    //             const responseMsg = res.data
    //             if (responseMsg.status === 0) {
    //                 setVendorList(responseMsg.data)
    //             } else {
    //                 message.error(responseMsg.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             message.error("网页发生错误,请联系管理员")
    //         })
    // }

    // 获取支付参数列表
    const getPayParamList = () => {
        setSearchLoading(true)
        payParamService.getPayParamList()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    setSearchLoading(false)
                    setTableData(responseMsg.data)
                } else {
                    setSearchLoading(false)
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                setSearchLoading(false)
                message.error("网页发生错误,请联系管理员")
            })
    }

    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Button type='primary' onClick={showRuleOperateModal}>新增规则</Button>
        </div>
    }

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "ID"
        },
        {
            key: "payParamName",
            dataIndex: "payParamName",
            title: "支付参数规则名称"
        },
        // {
        //     key: "vendor",
        //     dataIndex: "vendor",
        //     title: "卡商"
        // },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: any) => {
                if (record.status === "enable") {
                    return <span style={{ color: "green" }}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{ color: "red" }}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            title: '创建日期',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD ')
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                // eslint-disable-next-line
                <a onClick={() => onEdit(record)}>编辑</a>
            ),
        },
    ];

    const onEdit = (record: PayParamDataType) => {
        showRuleOperateModal()
        editInfo.current = record
        form.setFieldsValue({
            payParamName: record.payParamName,
            // operator: record.operator,
            // vendor: record.vendor,
            payPlatform: record.payPlatform,
            sellerId: record.sellerId,
            appId: record.appId,
            apiKey: record.apiKey,
            appSecret: record.appSecret,
            publicKey: record.publicKey,
            status: record.status,
        })
        setPayWay(record.payPlatform || 'WxPay')
    }

    const showRuleOperateModal = () => {
        setVisible(true)
    }

    const onRuleOperateCancel = () => {
        setVisible(false)
        form.resetFields()
        setPayWay('WxPay')
    }

    const onRuleOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            let promise = !editInfo.current ? payParamService.addPayParam(params) :
                payParamService.updatePayParam({ ...params, id: editInfo.current?.id })
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    form.resetFields()
                    getPayParamList()
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    const onPayWayChange = (e: any) => {
        setPayWay(e.target.value)
        form.setFieldsValue({
            sellerId: null,
            appId: null,
            apiKey: null,
            appSecret: null,
            publicKey: null
        })
    }

    const getFormItems = () => {
        if (payWay === 'WxPay') {
            return <>
                <Form.Item label={"商户号"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"sellerId"} initialValue={null}
                    rules={[{ required: true, message: '请输入商户号' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>

                <Form.Item label={"APIv2密钥"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"apiKey"} initialValue={null}
                    rules={[{ required: true, message: '请输入APIv2密钥' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>

            </>
        }
        return <>
            <Form.Item label={"公钥"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"publicKey"} initialValue={null}
                rules={[{ required: true, message: '请输入公钥' }]}>
                <Input placeholder='请输入' />
            </Form.Item>
        </>
    }

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}支付参数`}
            open={visible}
            onCancel={onRuleOperateCancel}
            onOk={onRuleOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"规则名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"payParamName"} initialValue={null}
                    rules={[{ required: true, message: '请输入规则名称' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                {/* <Form.Item label={"卡商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"vendor"} initialValue={null}
                    rules={[{ required: true, message: '请选择卡商' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        {
                            vendorList.map((item: any) => {
                                return <Option value={item.vendorName}>{item.vendorName}</Option>
                            })
                        }
                    </Select>
                </Form.Item> */}
                {/* <Form.Item label={"运营商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"operator"} initialValue={null}
                    rules={[{ required: true, message: '请选择运营商' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        <Option value="CMCC">中国移动</Option>
                        <Option value="CNC">中国联通</Option>
                        <Option value="CT">中国电信</Option>
                    </Select>
                </Form.Item> */}
                <Form.Item label={"支付平台"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"payPlatform"} initialValue='WxPay'
                    rules={[{ required: true, message: '请选择支付平台' }]}>
                    <Radio.Group onChange={onPayWayChange}>
                        <Radio value={'WxPay'}>微信</Radio>
                        <Radio value={'AliPay'}>支付宝</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={"appId"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"appId"} initialValue={null}
                    rules={[{ required: true, message: '请输入appId' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"appSecret"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"appSecret"} initialValue={null}
                    rules={[{ required: true, message: '请输入appSecret' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                {
                    getFormItems()
                }
                <Form.Item label={"状态"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"status"} initialValue='enable'
                    rules={[{ required: true, message: '请选择状态' }]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
