import React, { useEffect, useState } from "react";
import { useAuth } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, message } from "antd";
import './sign-in.less';
import signImg from '../../resources/img/logo3.png';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { authService, httpService, tokenService } from "../../service";
import { encode } from "js-base64";

const CryptoJS = require("crypto-js");

const _key_ = "DCYQ888";
const storageKey = "UINFO";

export const SignIn = () => {
    let navigate = useNavigate()
    let location = useLocation()
    // 导入验证上下文属性
    let auth = useAuth()
    // 登录按钮是否转圈
    const [loading, setLoading] = useState(false);

    // 获取URL来路，/ or /protected
    let from = location.state?.from?.pathname || '/'

    useEffect(() => {
        // 检查是否已经登录
        // console.log(auth.user);
        if (auth.user) {
            navigate(from, { replace: true })
        }
        // eslint-disable-next-line
    }, []);

    // 保存登录的信息
    const saveSignInfo = (values: any) => {
        const finalInfo = CryptoJS.AES.encrypt(JSON.stringify(values), _key_).toString();
        // console.log("加密信息: ", finalInfo);
        localStorage.setItem(storageKey, finalInfo);
    }

    // 还原登录的信息
    const getSignInfo = () => {
        const storageInfo = localStorage.getItem(storageKey)
        // console.log("storageInfo", storageInfo)
        if (storageInfo) {
            const signInfo = CryptoJS.AES.decrypt(storageInfo, _key_).toString(CryptoJS.enc.Utf8);
            // console.log("解密后信息: ", signInfo);
            return JSON.parse(signInfo);
        } else
            return {};
    }

    // 清除登录的信息
    const clearSignInfo = () => {
        localStorage.removeItem(storageKey);
    }

    /**
     * 提交登录表单
     * @param values
     */
    const onSubmit = (values: any) => {
        // const mockUser = {nickname: "张三", userId: 1001};
        // saveSignInfo(values);
        setLoading(true);
        httpService.postWithoutToken("auth/userLogin", { username: values.username, password: encode(values.password) })
            .then(res => {
                const result = res.data;
                if (result.status === 0) {
                    const token = result.data;
                    tokenService.setToken(token);
                    // 如果记住密码
                    if (values.remember) {
                        saveSignInfo(values);
                    } else {
                        clearSignInfo();
                    }
                    message.success("登录成功").then();
                    auth.signIn(token, () => {
                        navigate(from, { replace: true })
                    })
                    return authService.getUserByToken()
                } else {
                    const errMsg = result.msg;
                    message.error(errMsg).then();
                }
                setLoading(false);
            }).then(res => {
                if (res) {
                    const result = res.data;
                    if (result.status === 0) {
                        const data = result.data;
                        localStorage.setItem('userId', data?.id)
                        localStorage.setItem('username', data?.username)
                        if (data?.dealerId || data?.dealerId === "") {
                            localStorage.setItem('dealerId', data?.dealerId)
                        }
                    } else {
                        const errMsg = result.msg;
                        message.error(errMsg).then();
                    }
                }
            })
            .catch(err => {
                // console.error(err);
                setLoading(false);
                message.error("网络错误!").then();
            })
    }

    // const mockSignIn = () => {
    //     const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuaWNrbmFtZSI6IuW8oOS4iSIsInVzZXJJZCI6IjEwMDEifQ.X7gCfBEFEUrP9tdlvUzG-SVgmn0ZyczZsIiGM_nQSGg";
    //     auth.signIn(token, () => {
    //         navigate(from, { replace: true })
    //     })
    // }


    // 上次保存的登录信息
    const signFormDefaultValue = getSignInfo();
    // console.log("填充表单:",signFormDefaultValue)

    return (
        <div className={"sign-in-layout"}>

            {/*<h3>必须登录才能访问路由地址: {from}</h3>*/}
            {/*<form onSubmit={handleSubmit}>*/}
            {/*    <label>*/}
            {/*        用户名: <input type='text' name='username'/>{' '}*/}
            {/*        <button type='submit'>Login</button>*/}
            {/*    </label>*/}
            {/*</form>*/}

            <div className="signWarp">
                {/* eslint-disable-next-line */}
                <img className={"signLogo"} src={signImg} />
                <Form className={"signFrom"} onFinish={onSubmit} initialValues={signFormDefaultValue}>
                    <Form.Item name={'username'} rules={[{ required: true, message: '请输入用户名' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={"请输入用户名"} />
                    </Form.Item>
                    <Form.Item name={'password'} rules={[{ required: true, message: '请输入密码' }]}>
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type={"password"}
                            placeholder={"请输入密码"} />
                    </Form.Item>
                    <Form.Item name={'remember'} style={{ float: 'left' }} valuePropName={"checked"}>
                        <Checkbox>记住密码</Checkbox>
                    </Form.Item>
                    <Form.Item style={{ clear: "both" }}>
                        <Button style={{ width: "100%" }} type={"primary"} htmlType={'submit'}
                            loading={loading}>登录</Button>
                    </Form.Item>
                </Form>
                {/*<Button type={'primary'} onMouseUp={mockSignIn}>模拟登录</Button>*/}
            </div>
        </div>
    )

}
