import {httpService} from "../http-service";

class DownloadManageService {

    /**
     * 获取经销商列表
     */
    getDownloadList(data:any) {
        return httpService.post("/download/getDownloadList", data);
    }

    /**
     * 下载文件
     */
    downloadFile(id:string) {
        return httpService.post("download/downloadFile", {id}, undefined, 'blob');
    }
}

export const downloadManageService = new DownloadManageService();
