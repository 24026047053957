import { Button, Col, DatePicker, Form, Row, Select, Space } from "antd";
import React, { useState } from "react";
import { dataService } from "../../../service";
import { CChart } from "./CChart";
import { CTable } from "./CTable";
import dayjs from "dayjs"

const { RangePicker } = DatePicker

interface IDataViewBottomProps {
    batchList: any[]
}

export interface IPayload_Bottom {
    importBatchList: any[]
    startTime?: number
    endTime?: number
}

export const DataViewBottom = (props: IDataViewBottomProps) => {

    const { batchList } = props

    const [form] = Form.useForm();

    const [payload, setPayload] = useState<IPayload_Bottom>({
        importBatchList: [],
        startTime: Number(dayjs().subtract(29, 'days').startOf('days').valueOf()),
        endTime: Number(dayjs().endOf('days').valueOf())
    })

    const onFinish = () => {
        const { importBatchList, statisticalDate } = form.getFieldsValue()
        const params = {
            importBatchList,
            startTime: statisticalDate ? Number(statisticalDate[0].startOf('days').valueOf()) : undefined,
            endTime: statisticalDate ? Number(statisticalDate[1].endOf('days').valueOf()) : undefined,
        }
        setPayload(params)
    }

    return <div style={{ marginTop: 30 }}>
        <Form onFinish={onFinish} form={form}>
            {/* <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#fff",
                    paddingTop: 20
                }}
            > */}
            <Row style={{ background: "#fff", paddingTop: 20 }}>
                <Col span={6}>
                    <Form.Item
                        name={"statisticalDate"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"统计日期"}
                        initialValue={[dayjs().subtract(29, 'days').startOf('days'), dayjs().endOf('days')]}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"导入批次"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"importBatchList"} initialValue={[]}>
                        <Select
                            options={batchList}
                            placeholder='请选择'
                            mode='multiple'
                            maxTagCount={1}
                            maxTagTextLength={4}
                            style={{ minWidth: 120 }}
                        />
                    </Form.Item>
                </Col>


                <Col span={4} offset={9}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>

            {/* </div> */}

        </Form>
        <CChart payload={payload} query={dataService.getPayByNameData} title='​套餐购买分布' />
        <CChart payload={payload} query={dataService.getPayByMonthData} title='购买趋势' />
        <CTable payload={payload} />
    </div>
}