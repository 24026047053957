import {httpService} from "../http-service";

class AuthService {

    /**
     * 获取用户信息
     */
     getUserByToken() {
        return httpService.post("/auth/getUserByToken", {});
    }

    
}

export const authService = new AuthService();
