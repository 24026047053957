import { Button, Form, Input, message, Modal, Radio, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { rechargePackageManageService } from '../../../service/issue-manage/recharge-package-manage';

export interface PackageGroupDataType {
    id: string; // id
    key?: string;
    packageGroupName?: string // 套餐组名称
    status?: string; // 状态
    updateTime?: string; // 创建日期
}

interface IPackageGroupProps {
    updateShowPage: () => void
    updatePackageGroupList: () => void
}

export const PackageGroup = (props: IPackageGroupProps) => {

    const [form] = Form.useForm()

    const [tableData, setTableData] = useState<PackageGroupDataType[]>([])

    const [visible, setVisible] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)

    const editInfo = useRef<PackageGroupDataType | null>(null)

    useEffect(() => {
        // 查询逻辑
        getPayPackageGroupList()
        // eslint-disable-next-line
    }, [])

    // 获取套餐组列表
    const getPayPackageGroupList = () => {
        setLoading(true);
        rechargePackageManageService.getPayPackageGroupList()
            .then(res => {
                setLoading(false);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setTableData(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch((err) => {
                setLoading(false);
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    const columns: ColumnsType<PackageGroupDataType> = [
        {
            title: '套餐组名称',
            dataIndex: 'packageGroupName',
            key: 'packageGroupName',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.status === "enable") {
                    return <span style={{ color: "green" }}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{ color: "red" }}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            title: '创建日期',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                // eslint-disable-next-line
                <a onClick={() => onEdit(record)}>编辑</a>
            ),
        },
    ];

    const onEdit = (record: PackageGroupDataType) => {
        onShowPackageGroupOperateModal()
        editInfo.current = record
        form.setFieldsValue({
            packageGroupName: record.packageGroupName,
            status: record.status
        })
    }

    /**
     * 表格头部
     */
    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Space>
                <Button type='primary' onClick={onShowPackageGroupOperateModal}>新增套餐组</Button>
            </Space>
        </div>
    }

    const onShowPackageGroupOperateModal = () => {
        setVisible(true)
    }

    const onPackageGroupOperateCancel = () => {
        setVisible(false)
        form.resetFields()
        editInfo.current = null
    }

    const onPackageGroupOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            let promise = !editInfo.current ? rechargePackageManageService.addPayPackageGroup(params) :
                rechargePackageManageService.updatePayPackageGroup({ ...params, id: editInfo.current?.id })
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    form.resetFields()
                    getPayPackageGroupList()
                    props.updatePackageGroupList()
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    const returnPackage = () => {
        props.updateShowPage()
    }

    return <div className="sim-list-table">
        <h2
            style={{ textAlign: 'left', cursor: 'pointer' }}
            onClick={returnPackage}>
            {'< 返回'}
        </h2>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={loading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}套餐组`}
            open={visible}
            onCancel={onPackageGroupOperateCancel}
            onOk={onPackageGroupOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"套餐组名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageGroupName"} initialValue=''
                    rules={[{ required: true, message: '请输入套餐组名称' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"状态"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"status"} initialValue='enable'
                    rules={[{ required: true, message: '请选择状态' }]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}