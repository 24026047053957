import { Button, Form, Input, message, Modal, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { rechargePackageManageService, simService } from '../../../service';

interface PackageListDataType {

}

interface IPackageListProps {
    iccId: string
    info: any
}

const statusMap = {
    enable: '使用中',
    inactive: '未使用',
    expired: '已使用'
}

const sourceMap = {
    buy: '用户购买',
    give: '厂家赠送',
    transfer: '套餐转移',
    sync: '平台同步'
}

export const PackageList = (props: IPackageListProps) => {
    const [form] = Form.useForm()

    const { iccId, info } = props

    const [tableData, setTableData] = useState([])

    const [searchLoading, setSearchLoading] = useState(false)

    const [visible, setVisible] = useState(false)

    const [modalTitle, setModalTitle] = useState('')

    const [operate, setOperate] = useState('')

    const [packageList, setPackageList] = useState([])

    useEffect(() => {
        getSimPackageRelList()
        // eslint-disable-next-line
    }, [iccId])

    /**
     * 获取套餐列表
     */
    const getSimPackageRelList = () => {
        setSearchLoading(true)
        simService.getSimPackageRelList({ iccId })
            .then(res => {
                setSearchLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setTableData(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                setSearchLoading(false)
                message.error(err.msg)
            })
    }

    const onTransferPackage = () => {
        setOperate('transfer')
        setVisible(true)
        setModalTitle('转移套餐')
    }

    const onGivePackage = () => {
        const { packageGroupName } = info
        setOperate('give')
        setVisible(true)
        setModalTitle('赠送套餐')
        rechargePackageManageService.getRechargePackageList({
            packageGroupName
        })
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setPackageList(responseMsg.data);
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    // 表格定义
    const columns: ColumnsType<PackageListDataType> = [
        {
            title: '套餐名称',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: '使用情况',
            dataIndex: 'userFlow',
            key: 'userFlow',
            render: (text: any, record: any) => {
                return `${text || 0} / ${record?.flow || 0}`
            }
        },
        {
            title: '有效期',
            dataIndex: 'term',
            key: 'term',
            render: (text) => {
                return `${text}天`
            }
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: '套餐来源',
            dataIndex: 'source',
            key: 'source',
            render: (text: string) => {
                // @ts-ignore
                return sourceMap[text]
            }
        },
        {
            title: '使用状态',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                if (text === 'enable') {
                    return <div style={{ color: '#60BB47' }}>{statusMap[text]}</div>
                }
                // @ts-ignore
                return <div style={{ color: '#DE6261' }}>{statusMap[text] || ''}</div>
            }
        },
        {
            title: '购买日期',
            key: 'relationTime',
            dataIndex: 'relationTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '生效日期',
            dataIndex: 'relationTime',
            key: 'relationTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '到期日期',
            dataIndex: 'expireTime',
            key: 'expireTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
    ];

    const getFormItems = (operate: string) => {
        if (operate === 'give') {
            return <>
                <Form.Item label={"套餐名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageId"} initialValue={null}
                    rules={[{ required: true, message: '请选择套餐名称' }]}>
                    <Select placeholder='请选择套餐名称'>
                        {
                            packageList.map((item: any) => {
                                return <Select.Option key={item.id} value={item.id}>{item.packageName}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </>
        }
        return <>
            <Form.Item label={"转移至"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"toIccId"} initialValue={null}
                rules={[{ required: true, message: '请输入ICCID' }]}>
                <Input placeholder='请输入ICCID' />
            </Form.Item>
            <div className='ant-row ant-form-item-row'>
                <div className='ant-col ant-col-5 ant-form-item-label' />
                <div className='ant-col ant-col-19 ant-form-item-control'>
                    备注：转移套餐不改变购买日期、生效日期、到期日期和已使用流量、不产生新订单
                </div>
            </div>
        </>
    }

    const onGivePackageOK = () => {
        form.validateFields().then(() => {
            const { packageId } = form.getFieldsValue()
            let params = {
                iccId,
                packageId
            }
            simService.givePackage(params).then((res: any) => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    onOperateCancel()
                    message.success('赠送套餐成功')
                    getSimPackageRelList()
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error(err.msg)
                })
        })
    }

    const onTransferPackageOK = () => {
        form.validateFields().then(() => {
            const { toIccId } = form.getFieldsValue()
            let params = {
                iccId,
                toIccId
            }
            simService.transferPackage(params).then((res: any) => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    onOperateCancel()
                    message.success(responseMsg.msg)
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error(err.msg)
                })
        })
    }

    const onOperateOk = () => {
        if (operate === 'give') {
            onGivePackageOK()
        } else {
            // 转移套餐
            onTransferPackageOK()
        }
    }

    const onOperateCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    return <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>
            {/* <div style={{ fontSize: 16 }}>套餐列表</div> */}
            <div />
            <Space>
                <Button type='primary' onClick={onGivePackage}>赠送套餐</Button>
                <Button type='primary' onClick={onTransferPackage}>转移套餐</Button>
            </Space>

        </div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={modalTitle}
            open={visible}
            onCancel={onOperateCancel}
            onOk={onOperateOk}
        >
            <Form form={form}>
                {getFormItems(operate)}
            </Form>
        </Modal>
    </>
}