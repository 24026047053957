import {httpService} from "../http-service";

class RefundService{

    getPayOrderRefundList(data:any){
        const url = "/payPackage/refund/getPayOrderRefundList";
        return httpService.post(url, data);
    }

    approveRefund(data:any){
        const url = "/payPackage/refund/approveRefund";
        return httpService.post(url, data);
    }
}

export const refundService = new RefundService();