import React, { useState } from "react";
import './sim-manage.less';
import { SearchForm, IPayload } from './SearchForm';
import { DataList } from "./DataList";
import { SimDetail } from './sim-detail'
import { Drawer, Spin } from "antd";

export const SimManage = () => {

    const [payload, setPayload] = useState<IPayload>({
        importBatchList: [],
        iccIdList: [],
        vendorList: [],
        status: null,
        // startTime: Number(dayjs().startOf('days').subtract(7, 'days').valueOf()),
        // endTime: Number(dayjs().endOf('days').valueOf())
    })

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState(false);

    const [pageLoading, setPageLoading] = useState(false)

    const [showDetail, setShowSimDetail] = useState<boolean>(false);

    const [iccId, setIccId] = useState<string>('')

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    const updateShowPage = () => {
        setShowSimDetail(!showDetail)
    }

    const updateIccId = (value: string) => {
        setIccId(value)
        updateShowPage()
    }

    return <Spin spinning={pageLoading}>
        <div>
            <SearchForm updatePayload={updatePayload} payload={payload} searchLoading={searchLoading} />
            <DataList
                updateLoading={updateLoading}
                payload={payload}
                searchLoading={searchLoading}
                updateIccId={updateIccId}
            />
        </div>
        <Drawer
            open={showDetail}
            onClose={updateShowPage}
            title='详情'
            width={700}
            destroyOnClose
        >
            <SimDetail
                updateShowPage={updateShowPage}
                iccId={iccId}
                updateIccId={updateIccId}
                setPageLoading={setPageLoading}
            />
        </Drawer>
    </Spin>
}
