import { Button, Form, Input, message, Modal, Radio, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import { IPayload } from '../SearchForm';
import { rechargePackageManageService } from '../../../../service/issue-manage/recharge-package-manage';
import { PackageGroupDataType } from '../packge-group';
import dayjs from 'dayjs';

const { Option } = Select

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    updateShowPage: () => void
    searchLoading: boolean
    packageGroupList: PackageGroupDataType[]
}

interface RechargePackageDataType {
    id: string; // id
    key?: string;
    packageName?: string; // 套餐名称
    flow?: string; // 流量
    term?: number; // 有效期,天
    price?: number; // 价格,元
    packageGroupName: string; //套餐组名称 
    packageGroupId?: string;
    status?: string; // 状态
    updateTime?: string; // 创建日期
}


export const DataList = (props: IDataListProps) => {

    const [form] = Form.useForm()

    const { payload, updateLoading, searchLoading, packageGroupList } = props

    const [tableData, setTableData] = useState<RechargePackageDataType[]>([])

    const [visible, setVisible] = useState<boolean>(false)

    const editInfo = useRef<RechargePackageDataType | null>(null)

    useEffect(() => {
        // 查询逻辑
        getRechargePackageList()
        // eslint-disable-next-line
    }, [payload])

    // 获取套餐列表
    const getRechargePackageList = (isHideResMsg?: boolean) => {
        updateLoading(true);
        rechargePackageManageService.getRechargePackageList({
            ...payload
        })
            .then(res => {
                updateLoading(false);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTableData(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch((err) => {
                updateLoading(false);
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    const columns: ColumnsType<RechargePackageDataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '套餐名称',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: '流量',
            dataIndex: 'flow',
            key: 'flow',
        },
        {
            title: '有效期',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            render: (value) => {
                return <><span style={{ color: "green" }}>{value}</span><span
                    style={{ color: "gray", fontSize: "11px" }}> 元</span></>;
            }
        },
        {
            title: '套餐组',
            dataIndex: 'packageGroupName',
            key: 'packageGroupName',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.status === "enable") {
                    return <span style={{ color: "green" }}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{ color: "red" }}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            title: '创建日期',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                // eslint-disable-next-line
                <a onClick={() => onEdit(record)}>编辑</a>
            ),
        },
    ];

    const onEdit = (record: RechargePackageDataType) => {
        onShowPackageOperateModal()
        editInfo.current = record
        form.setFieldsValue({
            packageName: record.packageName,
            flow: record.flow,
            term: record.term,
            price: record.price,
            packageGroupId: record.packageGroupId,
            status: record.status
        })
    }

    const toPackageGroup = () => {
        props.updateShowPage()
    }

    /**
     * 表格头部
     */
    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Space>
                <Button type='primary' onClick={toPackageGroup}>套餐组管理</Button>
                <Button type='primary' onClick={onShowPackageOperateModal}>新增套餐</Button>
            </Space>
        </div>
    }

    const onShowPackageOperateModal = () => {
        setVisible(true)
    }

    const onPackageOperateCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const onPackageOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            let packageGroupName = packageGroupList.find(it => it.id === params.packageGroupId)?.packageGroupName
            let promise = !editInfo.current ? rechargePackageManageService.addRechargePackage({ ...params, packageGroupName }) :
                rechargePackageManageService.updateRechargePackage({ ...params, id: editInfo.current?.id, packageGroupName })
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    form.resetFields()
                    getRechargePackageList(true)
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    return <div className="sim-list-table">
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}套餐`}
            open={visible}
            onCancel={onPackageOperateCancel}
            onOk={onPackageOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"套餐组"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageGroupId"} initialValue=''
                    rules={[{ required: true, message: '请选择套餐组' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        {
                            packageGroupList.map((item) => {
                                return <Option value={item.id}>{item.packageGroupName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={"套餐名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageName"} initialValue=''
                    rules={[{ required: true, message: '请输入套餐名称' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"流量"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"flow"} initialValue=''
                    rules={[
                        { required: true, message: '请输入流量' },
                        { pattern: /^\d+(\.\d+)?$/, message: '请输入数字' }
                    ]}>
                    <Input placeholder='请输入' suffix="GB" />
                </Form.Item>
                <Form.Item label={"有效期"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"term"} initialValue=''
                    rules={[
                        { required: true, message: '请输入有效期' },
                        { pattern: /^\+?[1-9][0-9]*$/, message: '请输入非0正整数' }
                    ]}>
                    <Input placeholder='请输入' suffix="天" />
                </Form.Item>
                <Form.Item label={"套餐价格"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"price"} initialValue=''
                    rules={[
                        { required: true, message: '请选择输入套餐价格' },
                        { pattern: /^\d+(\.\d+)?$/, message: '请输入数字' }
                    ]}>
                    <Input placeholder='请输入' suffix="元" />
                </Form.Item>
                <Form.Item label={"状态"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"status"} initialValue='enable'
                    rules={[{ required: true, message: '请选择状态' }]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}