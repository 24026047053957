import { message, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { downloadManageService } from '../../../../service/admin';
import { IPayload } from '../SearchForm';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
}

interface DealerManageDataType {
    id: string; // id
    key?: string;
    dealerName?: string; // 经销商名称
    updateTime?: string; // 创建日期
}

export const DataList = (props: IDataListProps) => {

    const { payload, updateLoading, searchLoading } = props

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        // 查询逻辑
        getDownloadList()
        // eslint-disable-next-line
    }, [payload])

    /**
     * 查询下载列表
     */
    const getDownloadList = () => {
        updateLoading(true)
        downloadManageService.getDownloadList(payload)
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生未知错误")
            })
    }

    const columns = [
        {
            key: "fileName",
            dataIndex: "fileName",
            title: "文件名称"
        },
        {
            key: "userName",
            dataIndex: "userName",
            title: "操作人"
        },
        {
            key: "downloadTime",
            dataIndex: "downloadTime",
            title: "下载日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                <Space>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => onDownload(record)}>下载</a>
                </Space>
            ),
        },
    ];

    const onDownload = (record: DealerManageDataType) => {
        downloadManageService.downloadFile(record.id)
            .then(() => message.success("下载成功"))
            .catch((err) => message.error(err))
    }

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
    </div>
}