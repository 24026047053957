import { Form, DatePicker, Space, Button, Input, Row, Col } from 'antd';
import React from 'react';

const { RangePicker } = DatePicker

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    searchLoading: boolean
}

export interface IPayload {
    id: string | null
    iccId: string | null
    deviceId: string | null
    startTime?: number
    endTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { id, iccId, deviceId, bindingDate } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            id,
            iccId,
            deviceId,
            startTime: bindingDate ? Number(bindingDate[0].startOf('days').valueOf()) : undefined,
            endTime: bindingDate ? Number(bindingDate[1].endOf('days').valueOf()) : undefined
        }
        updatePayload && updatePayload(params)
    }
    const { searchLoading } = props
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={5}>
                    <Form.Item
                        name={"id"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"绑定ID"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"iccId"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"ICCID"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"deviceId"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"设备ID"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"bindingDate"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"统计日期"}
                    // initialValue={[dayjs().subtract(7, 'days').startOf('days'), dayjs().endOf('days')]}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4} offset={20}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}