import React, { useEffect } from 'react';
import './App.less';
import { Home } from "./pages/home";
import { Route, Routes } from "react-router-dom";
import { SignIn } from "./pages/auth";
import { AuthProvider } from "./context";
import { RequireAuth } from "./route";
import { PayParam, RechargePackageManage, SimList, BindingManage, VendorManage, SimManage } from "./pages/issue-manage";

// 日期组件中文
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import { ConfigProvider, message } from "antd";
import { Data } from "./pages/data-new";
import { OrderList, RefundList } from "./pages/finance";
import { UserManage, RoleManage, DealerManage, DownloadManage } from "./pages/admin";


function App() {
    /**
     * 应用启动时处理的逻辑
     */
    useEffect(() => {
        message.config({
            maxCount: 1
        })
        // eslint-disable-next-line
    }, [])

    return (
        <AuthProvider>
            <ConfigProvider locale={locale}>
                <div className="App">
                    <Routes>
                        <Route path={"/"} element={<RequireAuth><Home /></RequireAuth>}>
                            {/* 数据日报*/}
                            <Route path={"data"} element={<Data />} />
                            {/* 发行管理*/}
                            <Route path={"sim-list"} element={<SimList />} />
                            <Route path={"sim-manage"} element={<SimManage />} />
                            <Route path={"recharge-package-manage"} element={<RechargePackageManage />} />
                            <Route path={"pay-param"} element={<PayParam />} />
                            <Route path={"binding-manage"} element={<BindingManage />} />
                            <Route path={"vendor-manage"} element={<VendorManage />} />
                            {/* 财务管理 */}
                            <Route path={"order-list"} element={<OrderList />} />
                            <Route path={"refund-list"} element={<RefundList />} />
                            {/* 后台管理 */}
                            <Route path={"user-manage"} element={<UserManage />} />
                            <Route path={"role-manage"} element={<RoleManage />} />
                            <Route path={"dealer-manage"} element={<DealerManage />} />
                            <Route path={"download-manage"} element={<DownloadManage />} />
                        </Route>
                        <Route path={"auth/login"} element={<SignIn />} />
                    </Routes>

                </div>
            </ConfigProvider>
        </AuthProvider>
    );
}

export default App;
