import React, { useEffect, useState } from "react";
import { ProCard } from '@ant-design/pro-components';
import ReactEcharts from 'echarts-for-react';
import { message, Select, Spin } from "antd";
import { AxiosResponse } from "axios";
import { flatMapDeep } from "lodash";

interface IChartProps {
    payload: any
    query: (data: any) => Promise<AxiosResponse<any, any>>
    title: string
}

const options = [
    {
        label: "总购买",
        value: 0
    },
    {
        label: "新增购买",
        value: 1
    },
    {
        label: "续费购买",
        value: 2
    }
]

export const CChart = (props: IChartProps) => {

    const { payload, query, title } = props

    const [searchLoading, setSearchLoading] = useState(false)

    const [selectedValue, setSelectedValue] = useState(0)

    const [data, setData] = useState([])

    const [xAxis, setXAxis] = useState<any[]>([])

    const [series, setSeries] = useState<any[]>([])

    useEffect(() => {
        setSearchLoading(true)
        query({ ...payload, dealerId: localStorage.getItem("dealerId") })
            .then((res) => {
                setSearchLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    setData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                setSearchLoading(false)
                message.error(err.msg)
            })
        // eslint-disable-next-line
    }, [payload])

    useEffect(() => {
        if (data.length) {
            // @ts-ignore
            const series: any[] = data[selectedValue]?.data.map(it => {
                return {
                    ...it,
                    type: it.name === '数量' ? 'bar' : 'line',
                    yAxisIndex: it.name === '数量' ? 0 : 1,
                }
            }) || []
            const xAxis = Array.from(new Set(flatMapDeep(series.map(it => it.data.map((child: any) => child.name)))))
            setSeries(series)
            setXAxis(xAxis)
        }
    }, [data, selectedValue])

    const calcBarMaxValue = (name: '数量' | '金额') => {
        const values = series.find(it => it.name === name)?.data.map((child: any) => child.value) || [0]
        const maxValue = Math.floor(Math.max(...values) / 0.95)
        return maxValue || 10
    }

    const barMaxValue = calcBarMaxValue('数量')
    const lineMaxValue = calcBarMaxValue('金额')

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            },
            formatter: (params: any) => {
                let arr: any[] = []
                const item = params.map((it: any) => {
                    if (!arr.includes(it.seriesName)) {
                        return `<li style="list-style-type: none; line-height: 1.16rem; display: flex; align-items: center">
                                <span style="width: 0.12rem; height: 0.12rem; display: inline-table; margin-right: 0px; flex-shrink: 0; transform: scale(0.5); transform-origin: 0% center ">
                                    ${it.marker}
                                    </span>
                                    <span style="white-space: nowarp; overflow: hidden; text-overflow: ellipsis">
                                ${it.seriesName}
                                </span>
                                <span style="display: inline-block; float:none; margin-left: 0.37rem; margin-right: 0.5rem; flex: 1 0 0%; text-align: right">
                            ${Number(it.value).toLocaleString()}
                            </span>
                        </li>`;
                    }
                    return -1;
                });
                return `<ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: left">
                    <div style="text-align: left">${params[0].name}</div>
                    ${item.filter((it: any) => it !== -1).join("")}
                </ul>`;
            }
        },
        xAxis: [
            {
                type: 'category',
                data: xAxis,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '数量',
                min: 0,
                max: barMaxValue,
                splitNumber: 5,
                interval: barMaxValue / 5
            },
            {
                type: 'value',
                name: '金额/元',
                min: 0,
                max: lineMaxValue,
                splitNumber: 5,
                interval: lineMaxValue / 5
            }
        ],
        grid: {
            left: "4%",
            right: "4%",
            bottom: 30,
            top: 30,
            containLabel: true,
        },
        dataZoom: [{
            type: 'inside',
            xAxisIndex: 0,
            start: 0,
            end: 100,
        }],
        series
    }

    const onSelect = (value: number) => {
        setSelectedValue(value)
    }

    return <Spin spinning={searchLoading}>
        <ProCard
            title={title}
            extra={
                <Select
                    options={options}
                    onChange={onSelect}
                    value={selectedValue}
                    style={{ minWidth: 120 }}
                />
            }
            style={{ marginTop: 20 }}
        >
            <ReactEcharts
                option={option}
                style={{ height: 200, width: 'calc(100vw - 260px)' }}
                notMerge={true}
            />
        </ProCard>
    </Spin>
}