import { message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { dataService } from "../../../service";
import { CChart } from "./CChart";
import { CStatisticCard } from "./CStatisticCard";
import { IPayload_Top } from "./DataViewTop";
import './ViewContent.less';

interface IViewContentProps {
    payload: IPayload_Top
}

const payTotalMap = {
    "总订单金额": "payAmount",
    "总订单数": "orderNumber",
    "本月订单金额": "monthPayAmount",
    "本月订单数": "monthOrderNumber"
}

export const ViewContent = (props: IViewContentProps) => {
    const { payload } = props

    const [searchLoading, setLoading] = useState(false)

    const [payTotalData, setPayTotalData] = useState({})

    useEffect(() => {
        setLoading(true)
        dataService.getPayTotalData({ ...payload, dealerId: localStorage.getItem("dealerId") })
            .then((res) => {
                setLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    setPayTotalData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                setLoading(false)
                message.error(err.msg)
            })
    }, [payload])
    return <div className="container">
        {
            Object.keys(payTotalMap).map((key) => {
                const data = {
                    title: key,
                    //@ts-ignore
                    value: payTotalData[payTotalMap[key]],
                    suffix: key.endsWith("金额") ? "元" : undefined
                }
                return <div className="num">
                    <Spin spinning={searchLoading}>
                        <CStatisticCard data={data} />
                    </Spin>
                </div>
            })
        }
        <div className="chart">
            <CChart payload={payload} />
        </div>
    </div>
}