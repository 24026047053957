import { message, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { IPayload } from '../SearchForm';
import { orderService } from "../../../../service/finance";
import { orderStatusMap } from '../SearchForm';
import dayjs from 'dayjs';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
}

export const DataList = (props: IDataListProps) => {

    const { payload, updateLoading, searchLoading } = props

    const [tableData, setTableData] = useState([])

    const [total, setTotal] = useState(0)

    const pageObj = useRef({ pageNum: 1, pageSize: 10 })

    useEffect(() => {
        pageObj.current = { pageNum: 1, pageSize: 10 }
        // 查询逻辑
        getOrderList()
        // eslint-disable-next-line
    }, [payload])

    /**
     * 查询订单列表
     */
    const getOrderList = (isHideResMsg?: boolean) => {
        updateLoading(true)
        orderService.getOrderList({
            ...payload,
            dealerId: localStorage.getItem('dealerId') ?? payload.dealerId,
            pageNum: pageObj.current.pageNum,
            pageSize: pageObj.current.pageSize
        })
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTotal(responseMsg.data.totalElements)
                    setTableData(responseMsg.data.content);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then();
            })
    }

    const onPageChange = (page: number, pageSize: number) => {
        pageObj.current = { pageNum: page, pageSize }
        getOrderList(true)
    }

    const columns = [
        {
            key: "status",
            dataIndex: "status",
            title: "状态",
            render: (text: string | number) => {
                return orderStatusMap[text]
            }
        },
        {
            key: "orderNumber",
            dataIndex: "orderNumber",
            title: "订单号"
        },
        {
            key: "infoId",
            dataIndex: "infoId",
            title: "绑定ID"
        },

        {
            key: "price",
            dataIndex: "price",
            title: "订单金额"
        },
        {
            key: "realPrice",
            dataIndex: "realPrice",
            title: "实付金额"
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "payTime",
            dataIndex: "payTime",
            title: "付费日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "packageName",
            dataIndex: "packageName",
            title: "套餐"
        },
        {
            key: "importBatch",
            dataIndex: "importBatch",
            title: "导入批次"
        },
        {
            key: "iccId",
            dataIndex: "iccId",
            title: "ICCID"
        },
        {
            key: "dealerName",
            dataIndex: "dealerName",
            title: "经销商"
        },
    ];

    return <div>
        <Table
            rowKey={"id"}
            columns={columns}
            dataSource={tableData}
            loading={searchLoading}
            pagination={{
                showTotal: (total) => `共${total}条`,
                total: total,
                pageSize: pageObj.current.pageSize,
                current: pageObj.current.pageNum,
                onChange: onPageChange
            }}
            scroll={{ x: 'max-content' }}
        />
    </div>
}