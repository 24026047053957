import { Button, message, Spin, Tabs } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { simService } from '../../../service';
import { PackageList } from './packageList';
import { ChangeLog } from './ChangeLog';

interface ISimDetailProps {
    updateShowPage: () => void
    iccId: string
    updateIccId: (iccId: string) => void
    setPageLoading: Dispatch<SetStateAction<boolean>>
}

// const operatorMap: any = {
//     CMCC: '中国移动',
//     CNC: '中国联通',
//     CT: '中国电信'
// }

const statusMap: any = {
    activate: '激活',
    inactive: '未激活',
    electrify_activate: '通电激活',
    pause: '停机'
}

// const flowStatusMap: any = {
//     ADD: '是',
//     DEL: '否'
// }

const detailMap: any = {
    ICCID: 'ICCID',
    importBatch: '导入批次',
    vendor: '卡商',
    operator: '运营商',
    status: '状态',
    packageGroupName: '套餐组',
    payParam: '支付参数',
    package: '当前套餐',
    packageUsage: '使用情况',
    // flowStatus: '是否停机',
    activeTime: '激活时间',
    importTime: '导入时间'
}

export const SimDetail = (props: ISimDetailProps) => {

    const { iccId, setPageLoading } = props

    const [info, setInfo] = useState({})

    const [detailData, setDetailData] = useState<any>({})

    // const [tableData, setTableData] = useState<any[]>([])

    // const [searchLoading, setSearchLoading] = useState<boolean>(false)

    const [detailLoading, setDeatilLoading] = useState<boolean>(false)

    useEffect(() => {
        getSimCardDetail(iccId)
        // getBindingList()
        // eslint-disable-next-line
    }, [iccId])

    const getSimCardDetail = (iccId: string) => {
        setDeatilLoading(true)
        simService.getSimCardDetail({ iccId })
            .then(res => {
                setDeatilLoading(false)
                const responseMsg = res.data
                let data = responseMsg.data
                if (responseMsg.status === 0) {
                    setInfo(data)
                    handleDetailData(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                setDeatilLoading(false)
                message.error("网页发生错误,请联系管理员")
            })
    }

    // 获取绑定列表
    // const getBindingList = () => {
    //     setSearchLoading(true);
    //     bindingManageService.getSimDeviceRelList({
    //         iccId
    //     })
    //         .then((res) => {
    //             setSearchLoading(false);
    //             const responseMsg = res.data;
    //             if (responseMsg.status === 0) {
    //                 message.success('查询成功')
    //                 setTableData(responseMsg.data)
    //             } else {
    //                 message.error(responseMsg.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             setSearchLoading(false);
    //             message.error("网页发生错误,请联系管理员")
    //         })
    // }

    const handleDetailData = (data: any) => {
        let newData: any = {}
        for (let key in detailMap) {
            switch (key) {
                case 'ICCID':
                    newData[detailMap[key]] = iccId
                    break
                case 'status':
                    newData[detailMap[key]] = statusMap[data[key]]
                    break
                // case 'flowStatus':
                //     newData[detailMap[key]] = flowStatusMap[data[key]] || '是'
                //     break
                case 'operator':
                    newData[detailMap[key]] = data[key]
                    break
                case 'activeTime':
                    newData[detailMap[key]] = dayjs(data[key]).format('YYYY-MM-DD HH:mm:ss')
                    break
                case 'importTime':
                    newData[detailMap[key]] = dayjs(data[key]).format('YYYY-MM-DD HH:mm:ss')
                    break
                case 'package':
                    newData[detailMap[key]] = data['enablePackage'].join(',')
                    break
                case 'packageUsage':
                    newData[detailMap[key]] = `${data?.userFlow}GB / ${data?.flowTotal}GB`
                    break
                case 'payParam':
                    let params = [data?.wxPay, data?.aliPay].filter(it => Boolean(it))
                    newData[detailMap[key]] = params.join(',')
                    break
                default:
                    newData[detailMap[key]] = data[key]
            }
        }
        setDetailData(newData)
    }

    // const returnSimList = () => {
    //     props.updateIccId('')
    //     props.updateShowPage()
    // }

    // const columns = [
    //     {
    //         key: "id",
    //         dataIndex: "id",
    //         title: "绑定ID"
    //     },
    //     {
    //         key: "deviceId",
    //         dataIndex: "deviceId",
    //         title: "设备id"
    //     },
    //     {
    //         key: "relationTime",
    //         dataIndex: "relationTime",
    //         title: "绑定时间",
    //         render: (text: any) => {
    //             if (text) {
    //                 return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
    //             }
    //             return text
    //         }
    //     }
    // ]

    // const getTableHeader = () => {
    //     return <span style={{ fontSize: 14, fontWeight: 800, float: 'left' }}>
    //         绑定设备
    //     </span>
    // }

    const updateCardStatus = () => {
        let params = {
            iccId
        }
        setPageLoading(true)
        simService.syncSimCard(params)
            .then(res => {
                setPageLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                setPageLoading(false)
                message.error("网页发生错误,请联系管理员")
            })
    }

    const TabItems = [
        {
            label: "套餐列表",
            key: "packageList",
            children: <PackageList iccId={iccId} info={info} />
        },
        {
            label: "变动日志",
            key: "changeLog",
            children: <ChangeLog iccId={iccId} />
        }
    ]

    return <div>
        <div style={{ width: '100%', marginBottom: 16, textAlign: 'right' }}>
            {/* <div
                style={{
                    textAlign: 'left',
                    cursor: 'pointer',
                    fontSize: 16,
                    marginRight: 10,
                    padding: 5
                }}
                onClick={returnSimList}>
                {'<'}
            </div> */}
            <Button type='primary' onClick={updateCardStatus} icon={<SyncOutlined />}>更新卡状态</Button>
        </div>

        <Spin spinning={detailLoading}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginBottom: 16 }}>
                {
                    Object.keys(detailData).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {`${item}:`}&nbsp;&nbsp;{detailData[item]}
                        </div>
                    })
                }
            </div>
        </Spin>

        <Tabs defaultActiveKey="packageList" items={TabItems} />

        {/* <div style={{ width: '100%' }}>
            <Table
                rowKey='id'
                columns={columns}
                dataSource={tableData}
                loading={searchLoading}
                // scroll={{ x: 'max-content' }}
                title={getTableHeader}
            />
        </div> */}
    </div>
}