import { Form, message, Modal, Select } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { simService } from '../../../../service';
import { PackageGroupDataType } from '../../recharge-package-manage/packge-group';

const { Option } = Select

interface IPackageGroupChangeProps {
    selectedRowKeys: React.Key[]
    refresh: () => void
    packageGroupList: PackageGroupDataType[]
    // payParamList: any[]
}

export const PackageGroupChange = forwardRef((props: IPackageGroupChangeProps, ref) => {

    const [form] = Form.useForm()

    const [visible, setVisible] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        updateVisible: () => setVisible(true)
    }))

    const onPackageGroupChangeCancel = () => {
        setVisible(false)
    }

    const onPackageGroupChangeOk = () => {
        const { packageGroupId } = form.getFieldsValue()
        let packageGroupName = props.packageGroupList.find(it => it.id === packageGroupId)?.packageGroupName
        form.validateFields().then(() => {
            let params = {
                idList: props.selectedRowKeys,
                packageGroupId,
                packageGroupName
                // payParam
            }
            simService.batchUpdateSimCard(params)
                .then(res => {
                    const responseMsg = res.data;
                    if (responseMsg.status === 0) {
                        message.success('操作成功')
                        props.refresh()
                        setVisible(false)
                    } else {
                        message.error(responseMsg.msg)
                    }
                })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    return <div>
        <Modal
            title='变更套餐组和支付参数'
            open={visible}
            onCancel={onPackageGroupChangeCancel}
            onOk={onPackageGroupChangeOk}
        >
            <Form form={form}>
                <Form.Item label={"套餐组"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"packageGroupId"} initialValue={null}
                    rules={[{ required: true, message: '请选择套餐组' }]}>
                    <Select placeholder={"请选择"} allowClear>
                        {
                            props.packageGroupList.map((item) => {
                                return <Option value={item.id}>{item.packageGroupName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    </div>
})