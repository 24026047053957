import {httpService} from "../http-service";

class VendorManageService{

    /**
     * 获取卡商列表
     */
     getVendorList() {
        const url = "/vendor/getVendorList";
        return httpService.post(url, {});

    }

    /**
     * 新增卡商
     */
    addVendor(data:any) {
        const url = "/vendor/addVendor";
        return httpService.post(url, data);
    }
}

export const vendorManageService = new VendorManageService();