import React from "react";
import { useAuth } from "../context";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }: any) => {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.user) {
        return <Navigate to='/auth/login' state={{ from: location }} replace />;
    }
    return children;
}
