import {httpService} from "../http-service";

class OrderService{

    getOrderList(data:any){
        const url = "/payOrder/getPayOrderList";
        return httpService.post(url, data);
    }
    
    downloadPayOrder(data:any){
        const url = "payOrder/downloadPayOrder";
        return httpService.post(url, data, {}, "blob");
    }

}

export const orderService = new OrderService();
