import {httpService} from "../http-service";

class BindingService{

    /**
     * 获取绑定列表
     */
     getSimDeviceRelList(data:any) {
        const url = "/simDeviceRel/getSimDeviceRelList";
        return httpService.post(url, data);

    }

}

export const bindingManageService = new BindingService();