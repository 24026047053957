import React, { useState } from "react";
import { DataList } from "./DataList";
import { IPayload, SearchForm } from "./SearchForm";

export const DownloadManage = () => {

    const [payload, setPayload] = useState<IPayload>({})

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState(false);

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    return <div>
        <SearchForm updatePayload={updatePayload} payload={payload} searchLoading={searchLoading} />
        <DataList payload={payload} updateLoading={updateLoading} searchLoading={searchLoading} />
    </div>
}
