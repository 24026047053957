import {httpService} from "../http-service";

class SimService{

    /**
     * 获取sim卡列表
     */
    getSimList(data:any){
        const url = "/simCard/getSimCardList";
        return httpService.post(url, data);

    }

    /**
     * 导入sim卡Excel文件
     */
    importSim(data:any){
        const url = "/simCard/importFile";
        return httpService.post(url, data,{'Content-Type':'multipart/form-data'});
    }

    /**
     * 到处sim卡Excel文件
     */
    exportSim(){
        const url = "/simCard/download";
        return httpService.post(url, {},undefined,'blob');
    }

    /**
     * 批量编辑SIM
     */
     batchUpdateSimCard(data:any){
        const url = "/simCard/batchUpdateSimCard";
        return httpService.post(url, data);

    }

    /**
     * 获取批次号列表
     */
     getImportBatch(data?: any){
        const url = "/simCard/getImportBatch";
        return httpService.post(url, data || {});

    }

    /**
     * 编辑SIM流量状态
     */
     updateSimCardFlow(data:any){
        const url = "/simCard/updateSimCardFlow";
        return httpService.post(url, data);

    }

    /**
     * 新增SIM
     */
     addSimCard(data: any) {
        return httpService.post("/simCard/addSimCard", data);
    }

    /**
     * 修改SIM
     */
     updateSimCard(data: any) {
        return httpService.post("/simCard/updateSimCard", data);
    }

    /**
     * 查看卡信息
     */
     getSimCardDetail(data: any) {
        return httpService.post("/simCard/getSimCardDetail", data);
    }

    /**
     * 号段导入
     */
    importNumber(data: any) {
        return httpService.post("/simCard/importNumber", data);
    }

    /**
     * 批量更新卡状态
     */
    syncSimCard(data: any) {
        return httpService.post("/simCard/syncSimCard", data);
    }

    /**
     * 获取套餐列表
     */
     getSimPackageRelList(data: any) {
        return httpService.post("/simPackageRel/getSimPackageRelList", data);
    }

    /**
     * 转移套餐
     */
     transferPackage(data: any) {
        return httpService.post("/simPackageRel/transferPackage", data);
    }

    /**
     * 赠送套餐
     */
     givePackage(data: any) {
        return httpService.post("/simPackageRel/givePackage", data);
    }

    /**
     * 获取总数
     */
     getSimCardStatus(data: any) {
        return httpService.post("/simCard/getSimCardStatus", data);
    }

    /**
     * 划拨
     */
     transferSimCard(data: any) {
        return httpService.post("/simCard/transferSimCard", data);
    }

    /**
     * 出货
     */
     shipmentSimCard(data: any) {
        return httpService.post("/simCard/shipmentSimCard", data);
    }

    /**
     * 变动日志
     */
     getSimCardOperation(data: any) {
        return httpService.post("/simCard/getSimCardOperation", data);
    }
}

export const simService = new SimService();
