import { Form, DatePicker, Space, Button, Row, Col, Select } from 'antd';
import React from 'react';
import { PackageGroupDataType } from '../packge-group';

const { RangePicker } = DatePicker
const { Option } = Select

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
    searchLoading: boolean
    packageGroupList: PackageGroupDataType[]
}

export interface IPayload {
    packageGroupName: string | null
    status: string | null
    startTime?: number
    endTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { packageGroupName, status, createTime } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            packageGroupName,
            status,
            startTime: createTime ? Number(createTime[0].startOf('days').valueOf()) : undefined,
            endTime: createTime ? Number(createTime[1].endOf('days').valueOf()) : undefined
        }
        updatePayload && updatePayload(params)
    }
    const { searchLoading, packageGroupList } = props
    return <div className="recharge-package-form-wrap">
        <Form form={form} onFinish={onFinish}>
            <Row>
                <Col span={6}>
                    <Form.Item label={"套餐组"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"packageGroupName"} initialValue={null}>
                        <Select placeholder={"请选择"} allowClear>
                            {
                                packageGroupList.map((item) => {
                                    return <Option value={item.packageGroupName}>{item.packageGroupName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"状态"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"status"} initialValue={null}>
                        <Select placeholder={"请选择"} allowClear>
                            <Option value="enable">启用</Option>
                            <Option value="disable">禁用</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={"创建日期"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"createTime"}
                    // initialValue={[dayjs().subtract(7, 'days').startOf('days'), dayjs().endOf('days')]}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType='submit' loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}