import { Button, Col, Form, Select, Space } from "antd";
import React, { useState } from "react";
import { ViewContent } from "./ViewContent";

interface IDataViewTopProps {
    batchList: any[]
}

export interface IPayload_Top {
    importBatchList: any[]
}

export const DataViewTop = (props: IDataViewTopProps) => {

    const { batchList } = props

    const [form] = Form.useForm();

    const [payload, setPayload] = useState<IPayload_Top>({ importBatchList: [] })

    const onFinish = () => {
        const { importBatchList } = form.getFieldsValue()
        const params: IPayload_Top = {
            importBatchList
        }
        setPayload(params)
    }

    return <>
        <Form onFinish={onFinish} form={form}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#fff",
                    paddingTop: 20
                }}
            >
                <Col span={5}>
                    <Form.Item label={"导入批次"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"importBatchList"} initialValue={[]}>
                        <Select
                            options={batchList}
                            placeholder='请选择'
                            mode='multiple'
                            maxTagCount={1}
                            maxTagTextLength={4}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </div>

        </Form>
        <ViewContent payload={payload} />
    </>
}