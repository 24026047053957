import { Empty, message, Timeline } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { simService } from "../../../service";

interface IChangeLogProps {
    iccId: string
}

export const ChangeLog = (props: IChangeLogProps) => {

    const { iccId } = props

    const [logs, setLogs] = useState<{ children: string }[]>([])

    useEffect(() => {
        getSimCardOperation()
        // eslint-disable-next-line
    }, [])

    const getSimCardOperation = () => {
        simService.getSimCardOperation({ iccId })
            .then((res) => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    let data = responseMsg.data.map((d: string) => ({ children: d }))
                    setLogs(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error(err)
            })
    }

    return logs.length ? <div style={{ overflow: "auto", paddingTop: 10 }}>
        <Timeline
            items={logs}
        />
    </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}