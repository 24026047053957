import { Form, DatePicker, Space, Button, Input, Row, Col, Select, message } from 'antd';
// import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { simService } from '../../../../service';
import { dealerManageService } from '../../../../service/admin';
import { orderService } from '../../../../service/finance';

const { RangePicker } = DatePicker;
const { Option } = Select;


export const orderStatusMap: any = {
    "already": "已到账",
    // "process": "正在进行",
    "refund": "已退款",
    "cancel": "已取消",
    "fail": "支付失败",
}

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    searchLoading: boolean
}

export interface IPayload {
    orderNumber: string | null
    infoId: string | null
    importBatchList: string[]
    iccId: string | null
    status: string[]
    dealerId: string | null
    startTime?: number
    endTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const { searchLoading } = props

    const [form] = Form.useForm();

    const [batchList, setBatchList] = useState([])

    const [dealerList, setDealerList] = useState<any[]>([]);

    useEffect(() => {
        getImportBatch()
        getDealerList()
        // eslint-disable-next-line
    }, [])

    const getImportBatch = () => {
        simService.getImportBatch({ dealerId })
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data.map((it: any) => {
                        return {
                            label: it,
                            value: it
                        }
                    })
                    setBatchList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    /**
     * 查询经销商列表
     */
    const getDealerList = () => {
        dealerManageService.getDealerList({ dealerId })
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setDealerList(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                message.error("发生未知错误")
            })
    }

    const getParams = () => {
        const { orderNumber, infoId, status, createTime, importBatchList, iccId, dealerId } = form.getFieldsValue()
        let params: IPayload = {
            orderNumber,
            infoId,
            importBatchList,
            iccId,
            status,
            dealerId,
            startTime: createTime ? Number(createTime[0].startOf('days').valueOf()) : undefined,
            endTime: createTime ? Number(createTime[1].endOf('days').valueOf()) : undefined
        }
        return params
    }

    const onFinish = () => {
        const { updatePayload } = props
        const params = getParams()
        updatePayload && updatePayload(params)
    }

    const onDownLoad = () => {
        const params = getParams()
        orderService.downloadPayOrder({ ...params, dealerId: localStorage.getItem("dealerId") }).then(() => {

        })
    }
    const dealerId = localStorage.getItem("dealerId")
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={6}>
                    <Form.Item name={"createTime"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        label={"创建日期"}
                    // initialValue={[dayjs().subtract(7, 'days').startOf('days'), dayjs().endOf('days')]}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"orderNumber"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        label={"订单号"} initialValue={null}>
                        <Input placeholder={"请输入"} />
                    </Form.Item>
                </Col>
                {
                    dealerId && dealerId !== "" ? null : <Col span={6}>
                        <Form.Item label={"经销商"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                            name={"dealerId"} initialValue={null}>
                            <Select
                                allowClear
                                options={dealerList.map(d => ({ label: d.dealerName, value: d.id }))}
                                placeholder='请选择'
                            />
                        </Form.Item>
                    </Col>
                }
                <Col span={6}>
                    <Form.Item label={"导入批次"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"importBatchList"} initialValue={[]}>
                        <Select
                            options={batchList}
                            placeholder='请选择'
                            mode='multiple'
                            maxTagCount={1}
                            maxTagTextLength={4}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={"iccId"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"ICCID"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"infoId"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        label={"绑定ID"} initialValue={null}>
                        <Input placeholder={"请输入"} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"status"} label={"状态"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        initialValue={["already", "refund", "cancel", "fail"]}>
                        <Select placeholder={"请选择状态"} mode='multiple' maxTagCount={2}>
                            {Object.keys(orderStatusMap).map((k: string) => {
                                return <Option key={k}>{orderStatusMap[k]}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
            <div style={{ width: "100%", textAlign: 'right', marginBottom: 20 }}>
                <Button onClick={onDownLoad} type='primary'>下载表格数据</Button>
            </div>
        </Form>
    </div>
}