import { Button, Form, Input, message, Modal, Radio, Select, Table } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { roleManageService, userManageService } from '../../../../service/admin';
import { IPayload } from '../SearchForm';
import { encode } from "js-base64";
import dayjs from 'dayjs';

const { Option } = Select

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
    dealerList: any[]
}

interface UserManageDataType {
    id: string; // id
    key?: string;
    dealerId: string; // 经销商
    username?: string; // 账号名称
    password?: string; // 密码
    phone?: string; //手机号
    roleIdList?: string; // 用户角色
    status?: string; // 状态
    updateTime?: string; // 创建日期
}

export const DataList = (props: IDataListProps) => {

    const [form] = Form.useForm()

    const { payload, updateLoading, searchLoading, dealerList } = props

    const [tableData, setTableData] = useState([])

    const [roleList, setRoleList] = useState([])

    const [allRoleList, setAllRoleList] = useState([])

    const [visible, setVisible] = useState<boolean>(false)

    const editInfo = useRef<UserManageDataType | null>(null)

    useEffect(() => {
        getRoleList()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // 查询逻辑
        getUserList()
        // eslint-disable-next-line
    }, [payload])

    /**
     * 查询用户列表
     */
    const getUserList = (isHideResMsg?: boolean) => {
        updateLoading(true)
        userManageService.getUserList(payload)
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误")
            })
    }

    /**
     * 查询角色列表
     */
    const getRoleList = (dealerId?: string) => {
        updateLoading(true)
        roleManageService.getRoleList({ dealerId: dealerId ?? localStorage.getItem("dealerId") })
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    if (!dealerId) {
                        setAllRoleList(responseMsg.data.filter((it: any) => it.status === 'enable'))
                    }
                    setRoleList(responseMsg.data.filter((it: any) => it.status === 'enable'))
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then()
            })
    }

    const columns = [
        {
            key: "username",
            dataIndex: "username",
            title: "账号名称"
        },
        {
            key: "role",
            dataIndex: "role",
            title: "用户角色",
            render: (text: any, record: any) => {
                let str: string = ''
                if (Array.isArray(record?.roleIdList)) {
                    record?.roleIdList?.forEach((item: any) => {
                        //@ts-ignore
                        const name = allRoleList.find((role: any) => role.id === item)?.roleName || ''
                        str = `${str}${name} `
                    })
                }
                return str
            }
        },
        {
            key: "dealerName",
            dataIndex: "dealerName",
            title: "经销商"
        },
        {
            key: "updateTime",
            dataIndex: "updateTime",
            title: "创建日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: any) => {
                if (record.status === "enable") {
                    return <span style={{ color: "green" }}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{ color: "red" }}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                // eslint-disable-next-line
                <a onClick={() => onEdit(record)}>编辑</a>
            ),
        },
    ];

    const onEdit = (record: UserManageDataType) => {
        editInfo.current = record
        showUserOperateModal()
        let roleIdList = null;
        if (record.roleIdList && roleList.some((it: any) => record.roleIdList && it.id === record.roleIdList[0])) {
            roleIdList = record.roleIdList[0]
        }
        getRoleList(record.dealerId)
        form.setFieldsValue({
            username: record.username,
            password: record.password,
            phone: record.phone,
            roleIdList,
            status: record.status,
            dealerId: record.dealerId
        })
    }

    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Button type='primary' onClick={showUserOperateModal}>新增用户</Button>
        </div>
    }

    const showUserOperateModal = () => {
        setVisible(true)
    }

    const onUserOperateCancel = () => {
        editInfo.current = null
        setVisible(false)
        form.resetFields()
    }

    const onUserOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            let promise = !editInfo.current ? userManageService.addUser({ ...params, roleIdList: [params.roleIdList], password: encode(params.password) }) :
                userManageService.updateUser({ ...params, id: editInfo.current?.id, roleIdList: [params.roleIdList] })
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    form.resetFields()
                    getUserList(true)
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
            .catch(() => { })
    }

    const validator = (_: any, value: any) => {
        if (value || value === "") {
            return Promise.resolve()
        }
        return Promise.reject(new Error("请选择经销商"))
    }

    const dealerOptions = useMemo(() => {
        const options = dealerList.map(d => ({ label: d.dealerName, value: d.id }))
        options.unshift({ label: "系统", value: "" })
        return options
    }, [dealerList])

    const renderRoleList = useMemo(() => {
        if (form?.getFieldValue("dealerId") === "") {
            return roleList.filter((r: any) => r.dealerId === "")
        }
        return roleList
        // eslint-disable-next-line
    }, [roleList])

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}用户`}
            open={visible}
            onCancel={onUserOperateCancel}
            onOk={onUserOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"经销商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"dealerId"} initialValue={null}
                    rules={[{ validator: validator, message: '请选择经销商' }]}>
                    <Select
                        allowClear
                        placeholder='请选择'
                        options={dealerOptions}
                        onChange={(value) => {
                            getRoleList(value)
                            form.setFieldValue("roleIdList", null)
                        }}
                    />
                </Form.Item>
                <Form.Item label={"登录账户"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"username"} initialValue={null}
                    rules={[{ required: true, message: '请输入登录账户' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                {
                    !editInfo.current ? <Form.Item label={"登录密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                        name={"password"} initialValue={null}
                        rules={[{ required: true, message: '请输入登录密码' }]}>
                        <Input.Password placeholder='请输入' />
                    </Form.Item> : null
                }
                <Form.Item label={"手机号"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"phone"} initialValue={null}
                    rules={[{ required: true, message: '请输入手机号' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"用户角色"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"roleIdList"} initialValue={null}
                    rules={[{ required: true, message: '请选择用户角色' }]}>
                    <Select allowClear placeholder='请选择' disabled={(!form?.getFieldValue("dealerId") && form?.getFieldValue("dealerId") !== "")}>
                        {
                            renderRoleList.map((item: any) => {
                                return <Option value={item.id}>{item.roleName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={"状态"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"status"} initialValue='enable'
                    rules={[{ required: true, message: '请选择状态' }]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}