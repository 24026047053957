import { Form, Space, Button, DatePicker, Row, Col } from 'antd';
import React from 'react';

const { RangePicker } = DatePicker

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
    searchLoading: boolean
}

export interface IPayload {
    startTime?: number
    endTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { downloadTime } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            startTime: downloadTime ? Number(downloadTime[0].startOf('days').valueOf()) : undefined,
            endTime: downloadTime ? Number(downloadTime[1].endOf('days').valueOf()) : undefined
        }
        updatePayload && updatePayload(params)
    }
    const { searchLoading } = props
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={6}>
                    <Form.Item name={"downloadTime"} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        label={"下载时间"}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>

                <Col span={4} offset={14}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}