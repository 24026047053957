import { Button, Form, Input, message, Modal, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { vendorManageService } from '../../../service/issue-manage/vendor-manage';

export const VendorManage = () => {

    const [form] = Form.useForm()

    const [visible, setVisible] = useState<boolean>(false)
    const [searchLoading, setSearchLoading] = useState<boolean>(false)
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        getVendorList()
    }, [])

    const getVendorList = () => {
        setSearchLoading(true)
        vendorManageService.getVendorList()
            .then(res => {
                setSearchLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                setSearchLoading(true)
                message.error("网页发生错误,请联系管理员")
            })
    }

    const columns = [
        {
            key: "vendorName",
            dataIndex: "vendorName",
            title: "卡商"
        },
        {
            key: "vendorAsName",
            dataIndex: "vendorAsName",
            title: "卡商别名"
        },
        {
            key: "updateTime",
            dataIndex: "updateTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        }
    ];

    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Button type='primary' onClick={onShowVendorModal}>新增卡商</Button>
        </div>
    }

    const onShowVendorModal = () => {
        setVisible(true)
    }

    const onVendorCancel = () => {
        setVisible(false)
    }

    const onAddVendor = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            vendorManageService.addVendor(params)
                .then(res => {
                    setSearchLoading(false)
                    const responseMsg = res.data
                    if (responseMsg.status === 0) {
                        message.success('新增卡商成功')
                        getVendorList()
                        setVisible(false)
                    } else {
                        message.error(responseMsg.msg)
                        setVisible(false)
                    }
                })
                .catch((err) => {
                    setSearchLoading(true)
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    return <div>
        <Table
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title='新增卡商'
            okText='新增'
            visible={visible}
            onCancel={onVendorCancel}
            onOk={onAddVendor}
        >
            <Form form={form}>
                <Form.Item label={"卡商名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"vendorName"} initialValue=''
                    rules={[{ required: true, message: '请输入卡商名称' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"卡商别名"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"vendorAsName"} initialValue=''
                    rules={[{ required: true, message: '请输入卡商别名' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
            </Form>
        </Modal>
    </div>
}