import {httpService} from "../http-service";

class DealerManageService {

    /**
     * 获取经销商列表
     */
     getDealerList(data:any) {
        return httpService.post("/dealer/getDealerList", data);
    }

    /**
     * 新增经销商
     */
    addDealer(data:any) {
        return httpService.post("/dealer/addDealer", data);
    }

    /**
     * 修改经销商
     */
    updateDealer(data:any) {
        return httpService.post("/dealer/updateDealer", data);
    }

    /**
     * 删除经销商
     */
    deleteDealer(dealerId:string) {
        return httpService.post("/dealer/deleteDealer",{id: dealerId});
    }

}

export const dealerManageService = new DealerManageService();
