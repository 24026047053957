import {httpService} from "../http-service";

class DataService{

    getDataDaily(data:any){
        const url = "/data/getDataDaily";
        return httpService.post(url, data);
    }

    downloadDataDaily(data:any){
        const url = "data/downloadDataDaily";
        return httpService.post(url, data,{}, "blob");
    }

    getPayTotalData(data:any){
        const url = "data/getPayTotalData";
        return httpService.post(url, data);
    }

    getSimTotalData(data:any){
        const url = "data/getSimTotalData";
        return httpService.post(url, data);
    }

    getPayByNameData(data:any){
        const url = "data/getPayByNameData";
        return httpService.post(url, data);
    }

    getPayByMonthData(data:any){
        const url = "data/getPayByMonthData";
        return httpService.post(url, data);
    }

}

export const dataService = new DataService();
