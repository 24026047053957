import {httpService} from "../http-service";

export interface UserManage {
    username: string;
    phone: string;
    password?: string;
    nickname?: string;
    roleIdList?: number[];
    status?: string;
}

class UserManageService {

    /**
     * 获取管理管理员用户列表
     */
    getUserList(data:any) {
        return httpService.post("/user/getUserList", data);
    }

    /**
     * 添加管理员用户
     * @param adminUser
     */
    addUser(adminUser: UserManage) {
        return httpService.post('/user/addUser', adminUser);
    }

    /**
     * 更新用户
     * @param adminUser
     */
    updateUser(adminUser: UserManage) {
        return httpService.post('/user/updateUser', adminUser);
    }

    /**
     * 根据用户ID删除用户,软删除
     * @param userId
     */
    deleteUser(userId: string) {
        return httpService.post('/user/deleteUser', {id: userId});
    }

    /**
     * 修改用户密码
     */
    updatePassword(data:any) {
        return httpService.post("/user/updatePassword", data);
    }
}

export const userManageService = new UserManageService();
