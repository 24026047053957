import { Form, message, Modal, Select, Space, Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { simService } from "../../../../service";
import { PackageGroupDataType } from "../../recharge-package-manage/packge-group";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select

interface ITransferProps {
    packageGroupList: PackageGroupDataType[]
    batchList: any[]
    dealerList: any[]
}

export const Shipment = forwardRef((props: ITransferProps, ref) => {

    const [form] = Form.useForm()

    const { batchList, packageGroupList, dealerList } = props

    const [visible, setVisible] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        updateVisible: () => setVisible(true)
    }))

    const onShipmentCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const onShipmentOk = () => {
        form.validateFields().then(() => {
            const { packageGroupId } = form.getFieldsValue()
            let params = {
                ...form.getFieldsValue(),
                packageGroupName: packageGroupList.find(p => p.id === packageGroupId)?.packageGroupName
            }
            simService.shipmentSimCard(params)
                .then((res) => {
                    message.success("出货成功")
                    onShipmentCancel()
                })
                .catch((err) => {
                    message.error(err)
                })
        })
    }

    return <Modal
        title={
            <Space>
                <span>出货</span>
                <Tooltip title="将某一批次号出货给某一经销商。">
                    <ExclamationCircleOutlined />
                </Tooltip>
            </Space>
        }
        open={visible}
        onCancel={onShipmentCancel}
        onOk={onShipmentOk}
    >
        <Form form={form}>
            <Form.Item label={"批次号"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"importBatch"} initialValue={null}
                rules={[{ required: true, message: '请选择批次号' }]}>
                <Select
                    options={batchList}
                    placeholder='请选择'
                />
            </Form.Item>
            <Form.Item label={"经销商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"dealerId"} initialValue={null}
                rules={[{ required: true, message: '请选择套餐组' }]}>
                <Select
                    allowClear
                    placeholder='请选择'
                    options={dealerList.map(d => ({ label: d.dealerName, value: d.id }))}
                />
            </Form.Item>
            <Form.Item label={"套餐组"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"packageGroupId"} initialValue={null}
                rules={[{ required: true, message: '请选择套餐组' }]}>
                <Select placeholder={"请选择"} allowClear>
                    {
                        packageGroupList.map((item) => {
                            return <Option value={item.id}>{item.packageGroupName}</Option>
                        })
                    }
                </Select>
            </Form.Item>
        </Form>
    </Modal>
})