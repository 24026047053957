import React, { useState } from "react";
import { SearchForm, IPayload } from './SearchForm';
// import dayjs from "dayjs";
import { DataList } from "./DataList";

export const OrderList = () => {

    const [payload, setPayload] = useState<IPayload>({
        orderNumber: null,
        infoId: null,
        status: ["already", "refund", "cancel", "fail"],
        importBatchList: [],
        iccId: null,
        dealerId: null
        // startTime: Number(dayjs().startOf('days').subtract(7, 'days').valueOf()),
        // endTime: Number(dayjs().endOf('days').valueOf())
    })

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState(false);

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    return <div>
        <SearchForm updatePayload={updatePayload} searchLoading={searchLoading} />
        <DataList updateLoading={updateLoading} payload={payload} searchLoading={searchLoading} />
    </div>
}
