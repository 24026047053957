import axios from "axios";
import {tokenService} from "./token-service";

class HttpService {

    private baseUrl = process.env.REACT_APP_BASEURL;

    private handlerMap: { [key: number]: (res: any) => void } = {};

    register(code: number, handler: (res: any) => void) {
        this.handlerMap[code] = handler;
    }

    private __request(url: string, method: string, data: any, headers?: { [key: string]: string },responseType?:any) {
        return axios.request({
            baseURL: this.baseUrl,
            url,
            timeout: 60000,
            data,
            headers,
            method,
            responseType
        }).then((res) => {
            // status,msg,data
            const resData = res.data;
            if (this.handlerMap[resData.status] != null) {
                this.handlerMap[resData.status](resData);
            }
            if (res?.headers?.name) {
                this.handleDownload(res)
            }
            return res;
        });
    }

    get(url: string, headers?: { [key: string]: string }) {
        let tempHeaders: any = Object.assign({}, headers);
        tempHeaders["token"] = tokenService.getToken();
        return this.__request(url, 'get', null, tempHeaders);
    }

    post(url: string, data: any, headers?: { [key: string]: string },responseType?:string) {
        let tempHeaders: any = Object.assign({}, headers);
        tempHeaders["token"] = tokenService.getToken();
        if (!tempHeaders["Content-Type"]) {
            tempHeaders["Content-Type"] = "application/json";
        }
        return this.__request(url, 'post', data, tempHeaders, responseType);
    }

    getWithoutToken(url: string, headers?: { [key: string]: string }) {
        return this.__request(url, 'get', null, headers);
    }

    postWithoutToken(url: string, data: any, headers?: { [key: string]: string }) {
        return this.__request(url, 'post', data, headers);
    }

    handleDownload = (response:any) => {
        let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        let url = window.URL.createObjectURL(blob);
        let aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", decodeURIComponent(response.headers['name']));
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
    }

}

export const httpService: HttpService = new HttpService();
