import { Form, Space, Button, Input, Row, Col } from 'antd';
import React from 'react';

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
    searchLoading: boolean
}

export interface IPayload {
    dealerName: string | null
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { dealerName } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            dealerName,
        }
        updatePayload && updatePayload(params)
    }
    const { searchLoading } = props
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={5}>
                    <Form.Item
                        name={"dealerName"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"经销商"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={4} offset={15}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}