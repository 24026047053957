import React, { useEffect, useState } from "react";
import { SearchForm, IPayload } from './SearchForm';
import { DataList } from './DataList';
import { PackageGroup, PackageGroupDataType } from './packge-group';
import { rechargePackageManageService } from "../../../service/issue-manage/recharge-package-manage";
import { message } from "antd";

export const RechargePackageManage = () => {

    const [payload, setPayload] = useState<IPayload>({
        packageGroupName: null,
        status: null,
        // startTime: Number(dayjs().startOf('days').subtract(7, 'days').valueOf()),
        // endTime: Number(dayjs().endOf('days').valueOf())
    })

    const [packageGroupList, setPackageGroupList] = useState<PackageGroupDataType[]>([])

    useEffect(() => {
        //套餐组列表
        getPayPackageGroupList()
    }, [])

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [showPackgeGroup, setShowPackGroup] = useState<boolean>(false);

    // 获取套餐组列表
    const getPayPackageGroupList = () => {
        rechargePackageManageService.getPayPackageGroupList()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data?.filter((it: any) => it.status === 'enable') || []
                    setPackageGroupList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }


    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    const updateShowPage = () => {
        setShowPackGroup(!showPackgeGroup)
    }

    return !showPackgeGroup ? <div>
        <SearchForm
            updatePayload={updatePayload}
            payload={payload}
            searchLoading={searchLoading}
            packageGroupList={packageGroupList}
        />
        <DataList
            payload={payload}
            updateLoading={updateLoading}
            updateShowPage={updateShowPage}
            searchLoading={searchLoading}
            packageGroupList={packageGroupList}
        />
    </div> : <PackageGroup updateShowPage={updateShowPage} updatePackageGroupList={getPayPackageGroupList} />
}
