import React, { useEffect, useState } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Form, Input, MenuProps, Modal } from 'antd';
import { Avatar, Dropdown, Layout, Menu, message } from 'antd';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import './index.less';
import { getIcon } from "../../components";
import logo from '../../resources/img/logo.png';
import { useAuth } from "../../context";
import { menuService, userManageService } from "../../service/admin";
import { encode } from "js-base64";

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[] | null,
    path?: string
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        path,
    } as MenuItem;
}

export const Home = () => {

    const [form] = Form.useForm()

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation()

    const [menus, setMenus] = useState<any[]>([])
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const [pwdVisible, setPwdVisible] = useState<boolean>(false)

    useEffect(() => {
        if (auth.user) {
            menuService.getPowerMenuTree()
                .then(res => {
                    const responseMsg = res.data;
                    if (responseMsg.status === 0) {
                        let items = getMenuItems(responseMsg.data)
                        setMenus(items)
                        // 刷新时停留在当前页面
                        const path = sessionStorage.getItem('path')
                        let flatMenus: any[] = []
                        handleFlatMenus(responseMsg.data, flatMenus)
                        if (path) {
                            // 校验是否有当前页面
                            let existPath = flatMenus.find(it => it.url === path)
                            // 存在当前页面则保留当前页面,不存在则跳转默认第一个页面
                            if (existPath) {
                                navigate(existPath ? path : flatMenus[0].url)
                                setOpenKeys([existPath.parentKey])
                            } else {
                                navigate(flatMenus.length ? flatMenus[0].url : '/')
                                setOpenKeys(flatMenus.length ? [flatMenus[0].parentKey] : [])
                            }
                        } else {
                            navigate(flatMenus.length ? flatMenus[0].url : '/')
                            setOpenKeys(flatMenus.length ? [flatMenus[0].parentKey] : [])
                        }
                    } else {
                        message.error(responseMsg.msg)
                    }
                })
                .catch(err => {
                    message.error("网络错误!")
                })
        }
        // eslint-disable-next-line
    }, [])

    const getMenuItems = (menus: any[]) => {
        const items: any[] = []
        menus.forEach((item, index) => {
            items.push(getItem(
                item.menuName,
                item.url,
                item.icon ? getIcon(item.icon) : null,
                null,
                item.menuType === 'menu' ? undefined : item.url
            ))
            if (item.childList && item.childList.length) {
                items[index].children = getMenuItems(item.childList)

            }
        })
        return items
    }

    const handleFlatMenus = (menus: any[], flatMenus: any[], parentKey?: string) => {
        menus.forEach((item) => {
            if (item.menuType === 'page') {
                flatMenus.push({ ...item, parentKey: parentKey ? parentKey : item.url })
            }
            if (item.childList && item.childList.length) {
                handleFlatMenus(item.childList, flatMenus, item.url)
            }
        })
    }

    // 菜单收起
    const [collapsed, setCollapsed] = useState(false);

    const menuSelected = (e: any) => {
        // todo 跳转到指定的路由界面
        // console.log(e)
        // console.log(e.item.props.path)
        if (e.item.props.path) {
            navigate(e.item.props.path);
            sessionStorage.setItem('path', e.item.props.path)
        } else {
            navigate("/");
        }
    }

    const onOpenChange = (newOpenKeys: string[]) => {
        setOpenKeys(newOpenKeys)
    }

    const signOut = () => {
        localStorage.clear()
        auth.signOut(() => {
            navigate("/auth/login");
        });
    }

    const onPwdChange = () => {
        setPwdVisible(true)
    }

    const onPwdChangeCancel = () => {
        setPwdVisible(false)
        form.resetFields()
    }

    const onPwdChangeOk = () => {
        form.validateFields().then(() => {
            const { oldPwd, newPwd, confirmPwd } = form.getFieldsValue()
            let params = {
                userId: localStorage.getItem('userId'),
                oldPwd: encode(oldPwd),
                newPwd: encode(newPwd),
                confirmPwd: encode(confirmPwd)
            }
            userManageService.updatePassword(params)
                .then((res) => {
                    const result = res.data;
                    if (result.status === 0) {
                        message.success('修改密码成功')
                        onPwdChangeCancel()
                        signOut()
                    } else {
                        const errMsg = result.msg;
                        message.error(errMsg).then();
                    }
                })
                .catch((err) => {
                    message.error("网络错误!").then();
                })
        })
    }

    const dropDownItems: MenuItem[] = [
        getItem(<div onClick={onPwdChange}>修改密码</div>, 'passwordChange'),
        getItem(<div onClick={signOut}>退出登录</div>, 'data')
    ];

    return <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
            <div className="logo">
                <img src={logo} style={{ width: "40px", height: "40px" }} alt={'logo'} />
                <div style={{ fontSize: "22px", fontWeight: 900, color: "whitesmoke", paddingLeft: "10px" }}>大驰发卡平台
                </div>
            </div>
            <Menu
                theme="dark"
                defaultSelectedKeys={['data']}
                mode="inline"
                selectedKeys={[location.pathname]}
                openKeys={openKeys}
                items={menus}
                onSelect={menuSelected}
                onOpenChange={onOpenChange}
            />
        </Sider>
        <Layout className="site-layout">
            <Header className="site-layout-background"
                style={{
                    padding: "0 15px 0 0",
                    display: 'flex',
                    flexDirection: "row-reverse",
                    alignItems: 'center'
                }}>
                <Dropdown menu={{ items: dropDownItems }}>
                    <Avatar alt={'管理员'} size={35} icon={localStorage.getItem("username") ? null : <UserOutlined />} style={{ borderColor: "white" }}>
                        {localStorage.getItem("username")}
                    </Avatar>
                </Dropdown>
            </Header>
            <Content style={{ margin: '20px' }}>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>User</Breadcrumb.Item>*/}
                {/*    <Breadcrumb.Item>Bill</Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <div className="site-layout-background" style={{ height: "100%", width: "100%" }}>
                    <Outlet />
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>大驰发卡平台 ©2022 </Footer>
        </Layout>
        <Modal
            title={'修改密码'}
            open={pwdVisible}
            onCancel={onPwdChangeCancel}
            onOk={onPwdChangeOk}
        >
            <Form form={form}>
                <Form.Item label={"旧密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"oldPwd"} initialValue={null}
                    rules={[{ required: true, message: '请输入旧密码' }]}>
                    <Input.Password placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"新密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"newPwd"} initialValue={null}
                    rules={[{ required: true, message: '请输入新密码' }]}>
                    <Input.Password placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"确定密码"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"confirmPwd"} initialValue={null}
                    rules={[{ required: true, message: '请输入确定密码' }]}>
                    <Input.Password placeholder='请输入' />
                </Form.Item>
            </Form>
        </Modal>
    </Layout>;
}