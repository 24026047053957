import React, { useEffect, useState } from "react";
import { ProCard } from '@ant-design/pro-components';
import { Button, Checkbox, message, Space, Table } from "antd";
import { IPayload_Bottom } from "./DataViewBottom";
import { dataService } from "../../../service";

interface ICTableProps {
    payload: IPayload_Bottom;
}

export const CTable = (props: ICTableProps) => {

    const { payload } = props

    const [searchLoading, setSearchLoading] = useState(false)

    const [data, setData] = useState([])

    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setSearchLoading(true)
        dataService.getDataDaily({
            ...payload,
            isBatchGroup: checked,
            dealerId: localStorage.getItem("dealerId")
        })
            .then((res) => {
                setSearchLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    setData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                setSearchLoading(false)
                message.error(err.msg)
            })
    }, [payload, checked])

    const onSorter = (a: any, b: any, key: string) => {
        return a[key] - b[key]
    }

    let columns = [
        {
            key: "range",
            dataIndex: "range",
            title: "日期",
            sorter: (a: any, b: any) => {
                let aTime = new Date(a.range).getTime();
                let bTime = new Date(b.range).getTime();
                return aTime - bTime
            }
        },
        {
            key: "addActiveCardNumber",
            dataIndex: "addActiveCardNumber",
            title: "新增激活卡数",
            sorter: (a: any, b: any) => onSorter(a, b, 'addActiveCardNumber')
        },
        {
            key: "addActiveDeviceNumber",
            dataIndex: "addActiveDeviceNumber",
            title: "新增激活设备",
            sorter: (a: any, b: any) => onSorter(a, b, 'addActiveDeviceNumber')
        },
        {
            key: "payAmount",
            dataIndex: "payAmount",
            title: "总购买金额",
            sorter: (a: any, b: any) => onSorter(a, b, 'payAmount')
        },
        {
            key: "orderNumber",
            dataIndex: "orderNumber",
            title: "总购买订单数",
            sorter: (a: any, b: any) => onSorter(a, b, 'orderNumber')
        },
        {
            key: "firstPayAmount",
            dataIndex: "firstPayAmount",
            title: "新增购买金额",
            sorter: (a: any, b: any) => onSorter(a, b, 'firstPayAmount')
        },
        {
            key: "firstOrderNumber",
            dataIndex: "firstOrderNumber",
            title: "新增购买订单数",
            sorter: (a: any, b: any) => onSorter(a, b, 'firstOrderNumber')
        },
        {
            key: "nonFirstPayAmount",
            dataIndex: "nonFirstPayAmount",
            title: "续费购买订单金额",
            sorter: (a: any, b: any) => onSorter(a, b, 'nonFirstPayAmount')
        },
        {
            key: "nonFirstOrderNumber",
            dataIndex: "nonFirstOrderNumber",
            title: "续费购买订单数",
            sorter: (a: any, b: any) => onSorter(a, b, 'nonFirstOrderNumber')
        }
    ];

    const onCheck = (e: any) => {
        setChecked(e.target.checked)
    }

    const onDownLoad = () => {
        dataService.downloadDataDaily({
            ...payload,
            isBatchGroup: checked,
            dealerId: localStorage.getItem("dealerId")
        }).then(() => {

        })
    }

    if (checked) {
        // @ts-ignore
        columns.splice(1, 0, {
            key: "importBatch",
            dataIndex: "importBatch",
            title: "导入批次",
        })
    } else {
        columns = columns.filter(it => it.dataIndex !== "importBatch")
    }

    return <>
        <ProCard
            title="数据明细"
            extra={
                <Space>
                    <Checkbox onChange={onCheck} checked={checked}>按照批次分组查看</Checkbox>
                    <Button onClick={onDownLoad} type='primary'>下载表格数据</Button>
                </Space>
            }
            style={{ marginTop: 20 }}
        >
            <Table
                rowKey='id'
                columns={columns}
                dataSource={data}
                loading={searchLoading}
                scroll={{ x: 'max-content' }}
            />
        </ProCard>
    </>
}