import {decode} from "js-base64";

class TokenService {

    private tokenKey: string = "token";

    getToken() {
        return localStorage.getItem(this.tokenKey);
    }

    setToken(token: string) {
        localStorage.setItem(this.tokenKey, token);
    }

    clearToken(){
        localStorage.removeItem(this.tokenKey);
    }

    getPayload() {
        const res = this.getToken();
        if (res != null) {
            const tempToken = res.replace("_", "/").replace("-", "+"); // 添加这一行
            const json = decode(tempToken.split(".")[1]);
            // console.log("token payload: ",json);
            return JSON.parse(json);
        } else {
            return null;
        }
    }
}

export const tokenService: TokenService = new TokenService();
