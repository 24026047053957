import {httpService} from "../http-service";


export interface RechargePackageManage {
    id?: string;
    type?: string;
    name?: string;
    vendor?: string;
    operator?: string;
    flow?: string;
    status?: string;
    term?: string;
    price?: string;
}

export interface PackageGroup {
    id: string
    packageGroupName: string
    status: string
    createTime: string
}

class RechargePackageManageService {


    /**
     * 获取充值套餐列表
     */
    getRechargePackageList(data:any) {
        return httpService.post("/payPackage/getPayPackageList", data);
    }

    /**
     * 新增套餐
     */
    addRechargePackage(rechargePackage: RechargePackageManage) {
        return httpService.post("/payPackage/addPayPackage", rechargePackage);
    }

    /**
     * 修改套餐
     */
    updateRechargePackage(rechargePackage: RechargePackageManage) {
        return httpService.post("/payPackage/updatePayPackage", rechargePackage);
    }

    /**
     * 获取充值套餐组列表
     */
     getPayPackageGroupList() {
        return httpService.post("/payPackage/getPayPackageGroupList", {});
    }

    /**
     * 新增套餐组
     */
     addPayPackageGroup(packageGroup:Partial<PackageGroup>) {
        return httpService.post("/payPackage/addPayPackageGroup", packageGroup);
    }

    /**
     * 新增套餐组
     */
     updatePayPackageGroup(packageGroup:Partial<PackageGroup>) {
        return httpService.post("/payPackage/updatePayPackageGroup", packageGroup);
    }

}

export const rechargePackageManageService = new RechargePackageManageService();
