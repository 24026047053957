import { Button, message, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
// import { SyncOutlined } from '@ant-design/icons';
// import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { rechargePackageManageService, simService } from '../../../../service';
import { PackageGroupDataType } from '../../recharge-package-manage/packge-group';
// import { PackageGroupDataType } from '../../recharge-package-manage/packge-group';
import { IPayload } from '../SearchForm';
import { ImportCardNumber } from './ImportCardNumber';
import { Shipment } from './Shipment';
import { Transfer } from './Transfer';

// const { Option } = Select

// const operatorMap: any = {
//     CMCC: '中国移动',
//     CNC: '中国联通',
//     CT: '中国电信'
// }

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
    updateIccId: (iccId: string) => void
    dealerList: any[]
    batchList: any[]
}

interface SimDataType {
    id: string; // id
    key?: string;
    importBatch: string;
    iccId?: string;
    cardMerchant?: string;
    vendor?: string; // 卡商
    operator?: string; // 运营商
    status?: string; // 状态
    packageGroupName?: string; // 套餐组
    activeTime?: string; // 激活时间
    importTime?: string;  // 导入时间
}

// 卡状态 
const statusMap: any = {
    activate: '激活',
    inactive: '未激活',
    electrify_activate: '通电激活',
    pause: '停机'
}

// 卡状态对应需要操作的状态映射（如激活对应的操作则是停机）
const transformStatusMap: any = {
    activate: 'pause',
    pause: 'activate',
    inactive: 'activate',
    electrify_activate: 'activate'
}

type Status = 'activate' | 'inactive' | 'pause' | 'electrify_activate'

export const DataList = (props: IDataListProps) => {

    const { payload, searchLoading, dealerList, batchList } = props

    // 表格数据
    const [tableData, setTableData] = useState([])

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const ImportCardNumberRef = useRef<{ updateVisible: () => void }>()

    const TransferRef = useRef<{ updateVisible: () => void }>()

    const ShipmentRef = useRef<{ updateVisible: () => void }>()

    const [packageGroupList, setPackageGroupList] = useState<PackageGroupDataType[]>([])

    const [total, setTotal] = useState(0)

    const [cardSumInfo, setCardInfo] = useState<any>({})

    const pageObj = useRef({ pageNum: 1, pageSize: 10 })

    useEffect(() => {
        //套餐组列表
        getPayPackageGroupList()
    }, [])

    useEffect(() => {
        pageObj.current = { pageNum: 1, pageSize: 10 }
        // 查询逻辑
        getSimList(false)
        // 获取统计信息
        getSimCardStatus()
        // eslint-disable-next-line
    }, [payload])

    // 获取sim卡列表
    const getSimList = (isHideResMsg?: boolean) => {
        const { updateLoading } = props
        updateLoading(true);
        simService.getSimList({
            ...payload,
            pageNum: pageObj.current.pageNum,
            pageSize: pageObj.current.pageSize
        })
            .then(res => {
                updateLoading(false);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTotal(responseMsg.data.totalElements)
                    setTableData(responseMsg.data.content);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch((err) => {
                updateLoading(false);
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    const getSimCardStatus = () => {
        simService.getSimCardStatus({
            ...payload
        })
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setCardInfo(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    const onStatusChange = (status: string, id: string) => {
        let params = {
            // iccId,
            status,
            id
        }
        simService.updateSimCard(params).then((res: any) => {
            if (res?.data?.status === 0) {
                message.success('修改成功')
                getSimList(true)
            } else {
                message.error(res.data.msg)
            }
        })
            .catch((err) => {
                message.error("修改状态失败，请刷新页面后重试")
            })
    }

    const getSimOperation = (status: string, id: string) => {
        const showText = statusMap[transformStatusMap[status]] || ''
        // eslint-disable-next-line
        return <a onClick={() => onStatusChange(transformStatusMap[status], id)}>
            {showText}
        </a>
    }

    // 获取套餐组列表
    const getPayPackageGroupList = () => {
        rechargePackageManageService.getPayPackageGroupList()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data?.filter((it: any) => it.status === 'enable') || []
                    setPackageGroupList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    // 表格定义
    const columns: ColumnsType<SimDataType> = [
        {
            title: '导入批次',
            dataIndex: 'importBatch',
            key: 'importBatch',
        },
        {
            title: 'ICCID',
            dataIndex: 'iccId',
            key: 'iccId',
        },
        {
            title: '卡商',
            dataIndex: 'vendor',
            key: 'vendor',
        },
        {
            title: '运营商',
            dataIndex: 'operator',
            key: 'operator',
            render: (text: any, record: any) => {
                // return operatorMap[record.operator] || ''
                return text
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                return statusMap[value]
            }
        },
        {
            key: "dealerName",
            dataIndex: "dealerName",
            title: "经销商"
        },
        {
            title: '套餐组',
            dataIndex: 'packageGroupName',
            key: 'packageGroupName',
        },
        {
            title: '当前套餐',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: '导入时间',
            dataIndex: 'importTime',
            key: 'importTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '出货时间',
            dataIndex: 'shipmentTime',
            key: 'shipmentTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '激活时间',
            dataIndex: 'activeTime',
            key: 'activeTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '到期时间',
            dataIndex: 'expireTime',
            key: 'expireTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space>
                    {/* eslint-disable-next-line */}
                    {/* <a onClick={() => onEdit(record)}>编辑</a> */}
                    {getSimOperation(record.status as Status, record.id || '')}
                    {/* eslint-disable-next-line */}
                    <a onClick={() => props.updateIccId(record.iccId || '')}>查看详情</a>
                </Space>

            ),
        },
    ];

    const onImportCardNumberModal = () => {
        ImportCardNumberRef.current?.updateVisible()
    }

    const onTransferModal = () => {
        TransferRef.current?.updateVisible()
    }

    const onShipmentModal = () => {
        ShipmentRef.current?.updateVisible()
    }

    /**
     * 表格头部
     */
    const getTableHeader = () => {
        return <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 15px 0 0"
            }}
        >
            <div>
                共{cardSumInfo?.totalNumber || 0}张SIM卡，
                {cardSumInfo?.activatedNumber || 0}张为激活状态，
                {cardSumInfo?.inactiveNumber || 0}张为未激活状态
                {cardSumInfo?.electrifyNumber || 0}张为通电激活状态
                {cardSumInfo?.pauseNumber || 0}张为停机状态
            </div>
            <Space>
                {/* <Button type='primary' onClick={onShowSIMOperateModal}>新增SIM</Button> */}
                <Button type='primary' onClick={onImportCardNumberModal}>导入卡号</Button>
                <Button type='primary' onClick={onTransferModal}>划拨</Button>
                <Button type='primary' onClick={onShipmentModal}>出货</Button>
                {/* <Button type='primary' onClick={updateCardStatus} icon={<SyncOutlined />}>批量更新卡状态</Button> */}
            </Space>
        </div>
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onPageChange = (page: number, pageSize: number) => {
        pageObj.current = { pageNum: page, pageSize }
        getSimList(true)
    }

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            rowSelection={rowSelection}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
            pagination={{
                total: total,
                pageSize: pageObj.current.pageSize,
                current: pageObj.current.pageNum,
                onChange: onPageChange
            }}
        />
        <ImportCardNumber
            ref={ImportCardNumberRef}
            refresh={() => {
                pageObj.current = { ...pageObj.current, pageNum: 1 }
                getSimList(true)
            }}
            packageGroupList={packageGroupList}
        // payParamList={payParamList}
        />
        <Transfer
            ref={TransferRef}
            packageGroupList={packageGroupList}
            batchList={batchList}
        />
        <Shipment
            ref={ShipmentRef}
            packageGroupList={packageGroupList}
            dealerList={dealerList}
            batchList={batchList}
        />
    </div>
}