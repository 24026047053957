import { message } from "antd";
import React, { useEffect, useState } from "react";
import { simService } from "../../service";
import { DataViewTop } from "./data-view-top";
import { DataViewBottom } from "./data-view-bottom";

export const Data = () => {

    const [batchList, setBatchList] = useState([])

    useEffect(() => {
        getImportBatch()
    }, [])

    const getImportBatch = () => {
        simService.getImportBatch({ dealerId: localStorage.getItem("dealerId") })
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data.map((it: any) => {
                        return {
                            label: it,
                            value: it
                        }
                    })
                    setBatchList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    return <div>
        <DataViewTop batchList={batchList} />
        <DataViewBottom batchList={batchList} />
    </div>
}