import React, { useEffect, useState } from "react";
import ReactEcharts from 'echarts-for-react';
import { IPayload_Top } from "./DataViewTop";
import { dataService } from "../../../service";
import { message, Spin } from "antd";

interface ICChartProps {
    payload: IPayload_Top
}

const dataTransformMap = {
    "已激活": "activatedNumber",
    "未激活": "inactiveNumber",
    "通电激活": "electrifyNumber",
    "停机": "pauseNumber"
}

interface IData {
    total: number
    series: {
        name: string
        value: string | number
    }[]
}

export const CChart = (props: ICChartProps) => {

    const { payload } = props

    const [searchLoading, setSearchLoading] = useState(false)

    const [data, setData] = useState<IData>({ total: 0, series: [] })

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [payload])

    const getData = () => {
        setSearchLoading(true)
        dataService.getSimTotalData({ ...payload, dealerId: localStorage.getItem("dealerId") })
            .then((res) => {
                setSearchLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    const renderData = transformData(responseMsg.data)
                    setData(renderData)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                setSearchLoading(false)
                message.error(err.msg)
            })
    }

    const transformData = (oriData: any) => {
        const series = Object.keys(dataTransformMap).map((key) => {
            return {
                name: key,
                //@ts-ignore
                value: oriData[dataTransformMap[key]] || 0
            }
        })
        return {
            total: oriData?.totalNumber || 0,
            series
        }
    }

    const option = {
        title: {
            text: `{a|总ICCID数}\n{b|${data.total}}`,
            left: "center",
            top: "40%",
            textStyle: {
                rich: {
                    a: {
                        color: "#ccc",
                        fontSize: 14
                    },
                    b: {
                        fontSize: 16
                    }
                }
            }
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['55%', '70%'],
                label: {
                    alignTo: 'edge',
                    formatter: '{b}: {c}',
                    // edgeDistance: 20,
                },
                // emphasis: {
                //     itemStyle: {
                //         shadowBlur: 10,
                //         shadowOffsetX: 0,
                //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                //     }
                // },
                data: data.series
            }
        ]
    };
    return <Spin spinning={searchLoading}>
        <div style={{ textAlign: 'left', padding: 10 }}>
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>生命周期分布图</span>
            <ReactEcharts
                option={option}
                style={{ height: 200, width: '100%' }}
                notMerge={true}
            />
        </div>
    </Spin>
}
