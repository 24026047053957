import { Form, Space, Button, Input, Row, Col, Select } from 'antd';
import React from 'react';
const { Option } = Select

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
    searchLoading: boolean
    dealerList: any[]
}

export interface IPayload {
    roleName: string | null
    status: string | null
    dealerId: string | null
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { roleName, status, dealerId } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            roleName,
            status,
            dealerId
        }
        updatePayload && updatePayload(params)
    }
    const { searchLoading, dealerList } = props
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={6}>
                    <Form.Item
                        name={"dealerId"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"关联经销商"}
                        initialValue={null}
                    >
                        <Select allowClear placeholder='请选择' options={dealerList.map(d => ({ label: d.dealerName, value: d.id }))} />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"roleName"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"角色名称"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"status"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"状态"}
                        initialValue={null}
                    >
                        <Select placeholder='请选择' allowClear>
                            <Option value='enable'>启用</Option>
                            <Option value='disable'>禁用</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}