import { message, Modal, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { bindingManageService } from '../../../../service';
import { IPayload } from '../SearchForm';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
}

interface BindingDataType {
    id: string; // id
    key?: string;
    packageName?: string; // 套餐名称
    flow?: string; // 流量
    term?: number; // 有效期,天
    price?: number; // 价格,元
    packageGroupName: string; //套餐组名称 
    status?: string; // 状态
    updateTime?: string; // 创建日期
}

export const DataList = (props: IDataListProps) => {

    const { payload, updateLoading, searchLoading } = props

    const [tableData, setTableData] = useState<BindingDataType[]>([])

    const [packageList, setPackageList] = useState<any[]>([])

    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        // 查询逻辑
        getBindingList()
        // eslint-disable-next-line
    }, [payload])

    // 获取绑定列表
    const getBindingList = () => {
        updateLoading(true);
        bindingManageService.getSimDeviceRelList({
            ...payload
        })
            .then((res) => {
                updateLoading(false);
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('查询成功')
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                updateLoading(false);
                message.error("网页发生错误,请联系管理员")
            })
    }

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "绑定ID"
        },
        {
            key: "iccId",
            dataIndex: "iccId",
            title: "ICCID"
        },
        {
            key: "deviceId",
            dataIndex: "deviceId",
            title: "设备id"
        },
        {
            key: "package",
            dataIndex: "package",
            title: "套餐",
            render: (_: any, record: any) => {
                // eslint-disable-next-line
                return <a onClick={() => onShowPackageDetail(record?.packageList || [])}>查看</a>
            }
        },
        {
            key: "flow",
            dataIndex: "flow",
            title: "使用流量"
        },
        {
            key: "lastPayTime",
            dataIndex: "lastPayTime",
            title: "最后充值时间",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "relationTime",
            dataIndex: "relationTime",
            title: "绑定时间",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        }
    ];

    const packageColumns = [
        {
            title: '套餐名称',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: '套餐组',
            dataIndex: 'packageGroupName',
            key: 'packageGroupName',
        },
        {
            title: '有效期',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: '充值时间',
            dataIndex: 'relationTime',
            key: 'relationTime',
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
    ]

    const onShowPackageDetail = (list: any[]) => {
        setVisible(true)
        setPackageList(list)
    }

    const onClosePackageDetail = () => {
        setVisible(false)
        setPackageList([])
    }

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`套餐`}
            open={visible}
            onCancel={onClosePackageDetail}
            footer={null}
        >
            <Table
                rowKey='id'
                columns={packageColumns}
                dataSource={packageList}
                scroll={{ x: 'max-content' }}
            />
        </Modal>
    </div>
}