import { Form, Input, message, Modal, Select, Space, Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { simService } from "../../../../service";
import { PackageGroupDataType } from "../../recharge-package-manage/packge-group";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select

interface ITransferProps {
    packageGroupList: PackageGroupDataType[]
    batchList: any[]
}

export const Transfer = forwardRef((props: ITransferProps, ref) => {

    const [form] = Form.useForm()

    const { packageGroupList, batchList } = props

    const [visible, setVisible] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        updateVisible: () => setVisible(true)
    }))

    const onTransferCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const onTransferOk = () => {
        form.validateFields().then(() => {
            const { packageGroupId } = form.getFieldsValue()
            let params = {
                ...form.getFieldsValue(),
                packageGroupName: packageGroupList.find(p => p.id === packageGroupId)?.packageGroupName
            }
            simService.transferSimCard(params)
                .then((res) => {
                    message.success("划拨成功")
                    onTransferCancel()
                })
                .catch((err) => {
                    message.error(err)
                })
        })
    }

    return <Modal
        title={
            <Space>
                <span>划拨</span>
                <Tooltip title="将某一批次号中的部分卡号划拨到新的批次号中。">
                    <ExclamationCircleOutlined />
                </Tooltip>
            </Space>
        }
        open={visible}
        onCancel={onTransferCancel}
        onOk={onTransferOk}
    >
        <Form form={form}>
            <Form.Item label={"原始批次号"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"oldImportBatch"} initialValue={null}
                rules={[{ required: true, message: '请选择原始批次号' }]}>
                <Select
                    options={batchList}
                    placeholder='请选择'
                />
            </Form.Item>
            <Form.Item label={"开始号段"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"startNumber"} initialValue={[]}
                rules={[{ required: true, message: '请输入开始号段' }]}>
                <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={"结束号段"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"endNumber"} initialValue={[]}
                rules={[{ required: true, message: '请输入结束号段' }]}>
                <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={"新批次号名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"importBatch"} initialValue={null}
                rules={[{ required: true, message: '请输入新批次号名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item label={"可选套餐组"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                name={"packageGroupId"} initialValue={null}
                rules={[{ required: true, message: '请选择套餐组' }]}>
                <Select placeholder={"请选择"} allowClear>
                    {
                        packageGroupList.map((item) => {
                            return <Option value={item.id}>{item.packageGroupName}</Option>
                        })
                    }
                </Select>
            </Form.Item>
        </Form>
    </Modal>
})