import { Form, DatePicker, Space, Button, Input, Row, Col, Select } from 'antd';
// import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker
const { Option } = Select

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
}

export interface IPayload {
    orderNumber: string
    infoId: string
    refundStatus: string[]
    startTime?: number
    endTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const onFinish = () => {
        const { originationDate, orderNumber, infoId, refundStatus } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            orderNumber,
            infoId,
            refundStatus,
            startTime: originationDate ? Number(originationDate[0].startOf('days').valueOf()) : undefined,
            endTime: originationDate ? Number(originationDate[1].endOf('days').valueOf()) : undefined
        }
        updatePayload && updatePayload(params)
    }
    // const { payload } = props
    return <div>
        <Form onFinish={onFinish} form={form}>
            <Row>
                <Col span={7}>
                    <Form.Item
                        name={"originationDate"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"发起日期"}
                    // initialValue={payload.originationDate.map(it => dayjs(it))}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"orderNumber"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"订单号"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"infoId"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"绑定ID"}
                        initialValue={null}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item
                        name={"refundStatus"}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        label={"状态"}
                        initialValue={['unaudited']}
                    >
                        <Select allowClear mode='multiple'>
                            <Option value='unaudited'>未审核</Option>
                            <Option value='audited'>已审核</Option>
                            <Option value='refused'>已拒绝</Option>
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={'submit'}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}