import { Button, Form, Input, message, Modal, Radio, Select, Table, Tree } from 'antd';
import React, { Key, useEffect, useMemo, useRef, useState } from 'react';
import { menuService, roleManageService } from '../../../../service/admin';
import { IPayload } from '../SearchForm';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
    dealerList: any[]
}

interface RoleManageDataType {
    id: string; // id
    key?: string;
    roleName?: string; // 角色名称
    status?: string; // 状态
    updateTime?: string; // 创建日期
    dealerId: string; // 经销商
}


export const DataList = (props: IDataListProps) => {

    const [form] = Form.useForm()

    const { payload, updateLoading, searchLoading, dealerList } = props

    const [tableData, setTableData] = useState([])

    const [visible, setVisible] = useState<boolean>(false)

    const [menuTree, setMenuTree] = useState<any[]>([])

    const editInfo = useRef<RoleManageDataType | null>(null)

    const halfCheckedKeys = useRef([]) // 没全选的父节点

    useEffect(() => {
        // 获取菜单树
        getMenuTree()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // 查询逻辑
        getRoleList()
        // eslint-disable-next-line
    }, [payload])

    /**
     * 查询角色列表
     */
    const getRoleList = (isHideResMsg?: boolean) => {
        updateLoading(true)
        roleManageService.getRoleList(payload)
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then()
            })
    }

    /**
     * 获取菜单树
     */
    const getMenuTree = () => {
        updateLoading(true)
        menuService.getMenuTree()
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let menus: any[] = handleMenus(responseMsg.data)
                    setMenuTree(menus)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then()
            })
    }

    // 规整菜单格式
    const handleMenus = (data: any[]) => {
        data.forEach((item) => {
            item.title = item.menuName
            item.key = item.id
            if (item.childList && Array.isArray(item.childList) && item.childList.length) {
                item.children = item.childList
                handleMenus(item.childList)
            }
        })
        return data
    }

    // 获取菜单keys
    const getMenuSelectedKeys = (data: any[], keys: string[]) => {
        data.forEach((item) => {
            if (item.checked) {
                keys.push(item.id)
            }
            if (item.childList && Array.isArray(item.childList) && item.childList.length) {
                getMenuSelectedKeys(item.childList, keys)
            }
        })
    }

    const columns = [
        {
            key: "roleName",
            dataIndex: "roleName",
            title: "角色名称"
        },
        {
            key: "dealerName",
            dataIndex: "dealerName",
            title: "关联经销商"
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: any) => {
                if (record.status === "enable") {
                    return <span style={{ color: "green" }}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{ color: "red" }}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                // eslint-disable-next-line
                <a onClick={() => onEdit(record)}>编辑</a>
            ),
        },
    ];

    const onEdit = (record: RoleManageDataType) => {
        showRoleOperateModal()
        editInfo.current = record
        roleManageService.getRoleMenu(record.id).then((res) => {
            const responseMsg = res.data
            if (responseMsg.status === 0) {
                let menuSelectedKeys: string[] = [];
                // antd有父节点key存在子节点会全部选中，所以选中的节点要过滤有子节点的父节点
                const parentKeys = responseMsg.data.filter((it: any) => it.childList && it.checked).map((o: any) => o.id)
                getMenuSelectedKeys(responseMsg.data, menuSelectedKeys)
                let finalSelectedKeys = menuSelectedKeys.filter((key) => !parentKeys.includes(key))
                halfCheckedKeys.current = parentKeys
                form.setFieldsValue({
                    roleName: record.roleName,
                    status: record.status,
                    menus: finalSelectedKeys,
                    dealerId: record.dealerId
                })
            } else {
                message.error(responseMsg.msg)
            }
        })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then()
            })
    }

    const getTableHeader = () => {
        return <div style={{ textAlign: "right", margin: "0 15px 0 0", }}>
            <Button type='primary' onClick={showRoleOperateModal}>新增角色</Button>
        </div>
    }

    const showRoleOperateModal = () => {
        setVisible(true)
    }

    const onRoleOperateCancel = () => {
        setVisible(false)
        halfCheckedKeys.current = []
        form.resetFields()
    }

    const onRoleOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            let promise = !editInfo.current ? roleManageService.addRole({ ...params, menus: params.menus.concat(halfCheckedKeys.current) }) :
                roleManageService.updateRole({ ...params, menus: params.menus.concat(halfCheckedKeys.current), id: editInfo.current?.id })
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    halfCheckedKeys.current = []
                    form.resetFields()
                    getRoleList(true)
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
            .catch(() => { })
    }

    const onMenuCheck = (checkedKeys: { checked: Key[]; halfChecked: Key[]; } | Key[], e: any) => {
        halfCheckedKeys.current = e.halfCheckedKeys
        form.setFieldValue('menus', checkedKeys)
    }

    const validator = (_: any, value: any) => {
        if (value || value === "") {
            return Promise.resolve()
        }
        return Promise.reject(new Error("请选择经销商"))
    }

    const dealerOptions = useMemo(() => {
        const options = dealerList.map(d => ({ label: d.dealerName, value: d.id }))
        options.unshift({ label: "系统", value: "" })
        return options
    }, [dealerList])
    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}角色`}
            open={visible}
            onCancel={onRoleOperateCancel}
            onOk={onRoleOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"关联经销商"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"dealerId"} initialValue={null}
                    rules={[{ validator: validator, message: '请选择经销商' }]}>
                    <Select allowClear placeholder='请选择' options={dealerOptions} />
                </Form.Item>
                <Form.Item label={"角色名称"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"roleName"} initialValue={null}
                    rules={[{ required: true, message: '请输入角色名称' }]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label={"状态"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"status"} initialValue='enable'
                    rules={[{ required: true, message: '请选择状态' }]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={"模块权限"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                    name={"menus"} initialValue={[]} valuePropName='checkedKeys'>
                    <Tree
                        selectable={false}
                        showLine
                        checkable
                        defaultExpandAll
                        onCheck={onMenuCheck}
                        treeData={menuTree}
                    />
                </Form.Item>
            </Form>
        </Modal>
    </div>
}