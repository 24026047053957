import React, { useEffect, useState } from "react";
import './sim-list.less';
import { SearchForm, IPayload } from './SearchForm';
import { DataList } from "./DataList";
import { SimDetail } from './sim-detail'
import { Drawer, message, Spin } from "antd";
import { dealerManageService } from "../../../service/admin";
import { simService } from "../../../service";

export const SimList = () => {

    const [payload, setPayload] = useState<IPayload>({
        importBatchList: [],
        iccIdList: [],
        vendorList: [],
        status: null,
        dealerId: null
    })

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState(false);

    const [pageLoading, setPageLoading] = useState(false)

    const [showDetail, setShowSimDetail] = useState<boolean>(false);

    const [iccId, setIccId] = useState<string>('')

    const [dealerList, setDealerList] = useState<any[]>([]);

    const [batchList, setBatchList] = useState([])

    useEffect(() => {
        getDealerList()
        getImportBatch()
    }, [])

    /**
     * 查询经销商列表
     */
    const getDealerList = () => {
        dealerManageService.getDealerList({})
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    setDealerList(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                message.error("发生未知错误")
            })
    }

    /**
     * 获取批次号列表
     */
    const getImportBatch = () => {
        simService.getImportBatch()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data.map((it: any) => {
                        return {
                            label: it,
                            value: it
                        }
                    })
                    setBatchList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }

    const updateShowPage = () => {
        setShowSimDetail(!showDetail)
    }

    const updateIccId = (value: string) => {
        setIccId(value)
        updateShowPage()
    }

    return <Spin spinning={pageLoading}>
        <div>
            <SearchForm
                updatePayload={updatePayload}
                payload={payload}
                searchLoading={searchLoading}
                dealerList={dealerList}
                batchList={batchList}
            />
            <DataList
                updateLoading={updateLoading}
                payload={payload}
                searchLoading={searchLoading}
                updateIccId={updateIccId}
                dealerList={dealerList}
                batchList={batchList}
            />
        </div>
        <Drawer
            open={showDetail}
            onClose={updateShowPage}
            title='详情'
            width={700}
            destroyOnClose
        >
            <SimDetail
                updateShowPage={updateShowPage}
                iccId={iccId}
                updateIccId={updateIccId}
                setPageLoading={setPageLoading}
            />
        </Drawer>
    </Spin>
}
