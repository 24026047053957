import React, { useState } from "react";
import { SearchForm, IPayload } from './SearchForm';
import { DataList } from './DataList';

export const BindingManage = () => {

    const [payload, setPayload] = useState<IPayload>({
        id: null,
        iccId: null,
        deviceId: null,
        // startTime: Number(dayjs().startOf('days').subtract(7, 'days').valueOf()),
        // endTime: Number(dayjs().endOf('days').valueOf())
    })

    // 查询按钮loading
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const updatePayload = (params: IPayload) => {
        // if (!_.isEqual(payload, params)) {
        setPayload(params)
        // }
    }

    const updateLoading = (loading: boolean) => {
        setSearchLoading(loading)
    }


    return <div>
        <SearchForm updatePayload={updatePayload} searchLoading={searchLoading} />
        <DataList payload={payload} updateLoading={updateLoading} searchLoading={searchLoading} />
    </div>
}
