import { message, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { refundService } from '../../../../service/finance/refund-service';
import { IPayload } from '../SearchForm';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
}

const statusMap = {
    unaudited: '未审核',
    audited: '已审核',
    refused: '已拒绝'
}

export const DataList = (props: IDataListProps) => {

    const { payload, updateLoading, searchLoading } = props

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        // 查询逻辑
        getPayOrderRefundList()
        // eslint-disable-next-line
    }, [payload])

    // 获取退款列表
    const getPayOrderRefundList = (isHideResMsg?: boolean) => {
        updateLoading(true)
        refundService.getPayOrderRefundList(payload)
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTableData(responseMsg.data);
                } else {
                    message.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生位置错误").then();
            })
    }

    const onApproveRefund = (status: 'audited' | 'refused', record: any) => {
        refundService.approveRefund({
            refundStatus: status,
            orderNumber: record?.orderNumber
        })
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    getPayOrderRefundList(true)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员").then();
            })
    }

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "ID"
        },
        {
            key: "orderNumber",
            dataIndex: "orderNumber",
            title: "订单号"
        },
        {
            key: "infoId",
            dataIndex: "infoId",
            title: "绑定ID"
        },
        {
            key: "iccId",
            dataIndex: "iccId",
            title: "ICCID"
        },
        {
            key: "packageName",
            dataIndex: "packageName",
            title: "套餐"
        },
        {
            key: "price",
            dataIndex: "price",
            title: "价格"
        },
        {
            key: "refundStatus",
            dataIndex: "refundStatus",
            title: "状态",
            render: (text: string) => {
                // @ts-ignore
                return statusMap[text]
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "发起日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "auditTime",
            dataIndex: "auditTime",
            title: "审核日期",
            render: (text: any) => {
                if (text) {
                    return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "operation",
            dataIndex: "operation",
            title: "操作",
            render: (text: any, record: any) => {
                if (record.refundStatus === 'unaudited') {
                    return <Space>
                        <div
                            style={{ color: '#60BB47', cursor: 'pointer' }}
                            onClick={() => onApproveRefund('audited', record)}
                        >
                            通过
                            </div>
                        <div
                            style={{ color: '#DE6261', cursor: 'pointer' }}
                            onClick={() => onApproveRefund('refused', record)}>
                            拒绝
                            </div>
                    </Space>
                }
                return '-'
            }
        }
    ];

    return <div>
        <Table
            rowKey={"id"}
            columns={columns}
            dataSource={tableData}
            loading={searchLoading}
            scroll={{ x: 'max-content' }}
        />
    </div>
}